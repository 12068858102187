/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container, Row, Col, Navbar, Image } from "react-bootstrap";

/* Importing FontAwesome */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareInstagram } from "@fortawesome/free-brands-svg-icons";
import {
    faArrowUpRightFromSquare, faCircleRight,
    faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import sampleVideo from "../../assets/samplevideo.mp4";

/* Importing custom components */
import Appbar from "../../components/Appbar";
import { useIsMobile, useIsTablet } from "../../config/Screen";

const Home = () => {
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    const handleClick = () => {
        navigate("/auth/login");
    };

    function LaptopHome() {
        return (
            <>
                <Container fluid className="app-home-container mb-5">
                    {(!isMobile && !isTablet) && (
                        <>
                            <Row className="ms-2 poppins-semibold app-homepage-container-h1 mt-5">
                                Find your creative partner
                                
                            </Row>
                            <Row className="ms-2  poppins-semibold app-homepage-container-h1">
                                at Letz create
                            </Row>
                            <Row className="ms-2 poppins-regular app-homepage-container-h2">
                                Join a network of Content creators, models, make-up artists,
                               
                            </Row>
                            <Row className="ms-2 poppins-regular app-homepage-container-h2">
                                
                                hairstylists, photographers, videographers, editors to
                                
                            </Row>
                            <Row className="ms-2  poppins-regular app-homepage-container-h2">
                                
                                succeed in your creator journey.
                            </Row>
                            <Row className="ms-2 mt-4">
                                <Row
                                    className="ms-1 align-items-center text-center poppins-semibold app-home-container-btn"
                                    onClick={handleClick}
                                >
                                    JOIN NOW<Col className="align-items-center justify-content-center d-flex position-relative"><FontAwesomeIcon className=" position-absolute end-0" icon={faCircleRight}  /></Col>
                                </Row>
                                
                            </Row>
                        </>
                    )}
                    {(isMobile || isTablet) && (
                        <Container>
                            <video
                                controls
                                playsInline
                                autoPlay
                                muted
                                loop
                                className="app-homepage-background-video"
                            >
                                <source src={sampleVideo} type="video/mp4" />
                            </video>
                            <Row className="poppins-semibold app-homepage-container-h1-mobile mt-5">
                                Find your 

                            </Row>
                            <Row className="poppins-semibold app-homepage-container-h1-mobile">
                               creative partner 
                            </Row>
                            <Row className="poppins-semibold app-homepage-container-h1-mobile">
                                at Letz create
                            </Row>
                            <Row className="poppins-regular app-homepage-container-h2-mobile">
                                Join a network of Content creators, models,

                            </Row>
                            <Row className="poppins-regular app-homepage-container-h2-mobile">

                                make-up artists, hairstylists, photographers,

                            </Row>
                            <Row className="poppins-regular app-homepage-container-h2-mobile">

                                videographers, editors to succeed in your
                            </Row>
                            <Row className="poppins-regular app-homepage-container-h2-mobile">

                                creator journey. 
                            </Row>
                            <Row className="mt-4 ms-0">
                                <Row
                                    className="align-items-center text-center poppins-semibold app-home-container-btn"
                                    onClick={handleClick}
                                >
                                    JOIN NOW<Col className="align-items-center justify-content-center d-flex position-relative"><FontAwesomeIcon className=" position-absolute end-0" icon={faCircleRight} /></Col>
                                </Row>

                            </Row>
                        </Container>
                    )}
                </Container>
            </>
        );
    }
    return (
        <Container>
            <Row>
                <Col>
            {(isMobile || isTablet) &&(
                        <Row className="mt-5">
                <Navbar.Brand href="https://letzcreate.in" className="app-appbar-title">
                    <Image src={require("../../assets/img/logomobile.png")} />
                </Navbar.Brand>
            </Row>
            )}
            {(!isMobile && !isTablet) && (
            <Row className="ms-1 mt-5">
                <Navbar.Brand href="https://letzcreate.in" className="app-appbar-title">
                    <Image src={require("../../assets/img/newlogo.png")} />
                    </Navbar.Brand>
                </Row>
            )}
            <Row>
                {LaptopHome()}
            </Row>
            </Col>
                {(!isMobile && !isTablet) && (
                    <Col xxl={6} xl={6} lg={6} md={6} className="mt-5">
                        <div className="app-homepage-video-container ">
                            <video
                                // controls
                                playsInline
                                autoPlay
                                muted
                                loop
                                className="app-honmepage-responsive-video"
                            >
                                <source src={sampleVideo} type="video/mp4" />
                            </video>
                        </div>
                    </Col>
                )}
            </Row>
        </Container>
    );
};

export default Home;
