import React from "react";
import { useSelector } from "react-redux";

// User defined components
import Appbar from "../../components/Appbar";
import Unauthorized from "../../components/Errors/Unauthorized";
import JobList from "./JobList";


const JobFeed = () => {
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);

  return (
    <>
      <Appbar />
      {!isLoggedIn ? <Unauthorized /> : <JobList />}
      {/* {<Landingpage />} */}
      {/* {<AppFooter />} */}
    </>
  );
};

export default JobFeed;