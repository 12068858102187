import React from "react";
import { Container, Alert } from "react-bootstrap";

const Unauthorized = () => {
  return (
    <Container>
      <Alert variant="danger" className="mt-5">
        Unauthorized access! You do not have permission to view this page.
      </Alert>
    </Container>
  );
};

export default Unauthorized;
