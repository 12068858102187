import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Action types
export const actionTypes = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
};

// Initial state
const initialState = {
  isLoggedIn: false,
  userId: null,
  token: null, // Add token property to the initial state
};

// Reducer function
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN:
      return {
        ...state,
        isLoggedIn: true,
        userId: action.payload.userId,
        token: action.payload.token, // Set the token value from the action payload
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        userId: null,
        token: null, // Reset the token value to null on logout
      };
    default:
      return state;
  }
};

// Action creators
export const actionCreators = {
  login: (token, userId,) => {
    return { type: actionTypes.LOGIN, payload: { token, userId } };
  },
  logout: () => {
    return { type: actionTypes.LOGOUT };
  },
};

const persistConfig = {
  key: "auth",
  storage,
};

export default persistReducer(persistConfig, authReducer);
