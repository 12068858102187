import React, { useState } from "react";

// Bootstrap components
import { Row, Col, Form, Container, Card } from "react-bootstrap";

// User defined components
import { useIsMobile } from "../../../config/Screen";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setEmailErr("Email is required.");
      return;
    }

    setEmailErr("");

    // Check if email is valid
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    if (!isValidEmail) {
      setEmailErr("Invalid email address.");
      return;
    }

    setEmailErr("");
  };

  function forgotPasswordHeader() {
    return (
      <Row>
        <Col>
          <h2 className="app-login-h2 crimson-pro-thin">Forget Password?</h2>
          <p className="app-login-p poppins-light">
            Empowering individuals and brands
          </p>
        </Col>
      </Row>
    );
  }

  function getUserEmail() {
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formForgotPassword">
          <Form.Control
            className="app-login-form-input"
            type="text"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            isInvalid={!!emailErr}
          />
          <Form.Control.Feedback type="invalid">
            {emailErr}
          </Form.Control.Feedback>
        </Form.Group>
        <Row className="mt-5 justify-content-center">
          <button
            className="app-login-button poppins-semibold"
            variant="primary"
            type="submit"
          >
            Submit
          </button>
        </Row>
      </Form>
    );
  }

  if (useIsMobile()) {
    return (
      <div className="app-login-sm-container">
        <Container>
          <Row className="justify-content-center">
            {forgotPasswordHeader()}
            {getUserEmail()}
          </Row>
        </Container>
      </div>
    );
  } else {
    return (
      <Container className="app-login-container">
        <Row className="justify-content-center">
          <Card className="app-login-card">
            {forgotPasswordHeader()}
            {getUserEmail()}
          </Card>
        </Row>
      </Container>
    );
  }
};

export default ForgotPassword;
