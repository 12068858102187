import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Fontawesome components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareArrowUpRight } from "@fortawesome/free-solid-svg-icons";

// Bootstrap components
import { Card, Col, Image, ProgressBar, Row } from "react-bootstrap";
import axiosInstance from "../../config/Axios";

// UserProfile component
const UserProfile = () => {
  const [userName, setUserName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const userId = useSelector(({ auth }) => auth.userId);

  useEffect(() => {
    const fetchUser = async () => {
      const response = await axiosInstance.get(`/users/${userId}`);
      console.log(response.data);
      setUserName(response.data.name);
      setUserPhone(response.data.mobile);
    };

    fetchUser();
  }, [userId]);

  return (
    <Card className="app-job-list-user-card">
      <Card.Body>
        <Row>
          <Col lg={2}>
            <Image src="https://via.placeholder.com/40" alt="" roundedCircle />
          </Col>
          <Col lg={10}>
            <Row className="app-job-list-user-card-name ms-3">{userName}</Row>
            <Row className="app-job-list-user-card-contact ms-3">
              {userPhone}
            </Row>
          </Col>
        </Row>
        <Row className="app-job-list-user-card-status poppins-regular mt-2">
          <Col>
            <p className="mb-0">45% profile completed</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <ProgressBar
              now={45}
              className="app-job-list-user-card-progress"
              variant="success"
            />
          </Col>
        </Row>
        <Row className="app-job-list-user-card-link mt-3">
          <Col lg={10}>Link your account</Col>
          <Col lg={2} className="text-end">
            <FontAwesomeIcon icon={faSquareArrowUpRight} />
          </Col>
        </Row>
        <Row className="app-job-list-user-card-details poppins-regular">
          <span>Help you gain the trust with brands</span>
        </Row>
        <Row className="app-job-list-user-card-link mt-3">
          <Col lg={10}>Boost your profile</Col>
          <Col lg={2} className="text-end">
            <FontAwesomeIcon icon={faSquareArrowUpRight} />
          </Col>
        </Row>
        <Row className="app-job-list-user-card-details poppins-regular">
          <span>Higher chances of connecting with brands</span>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default UserProfile;
