import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Bootstrap components
import { Row, Col, Tab, Tabs, Button, Form } from "react-bootstrap";

// Font Awesome icons used in the component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheck } from "@fortawesome/free-solid-svg-icons";

// This component is used to get the name of the influencer
const InfluencerName = ({ phone }) => {
  const navigate = useNavigate();

  // State variables to store the name and error message
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");

  function handleBackClick() {
    navigate("./login", { replace: true });
    window.history.back();
  }

  const handleSubmit = async () => {
    if (!name) {
      setNameErr("Please enter your name");
      return;
    }

    // Reset the error message
    setNameErr("");

    // Save the name in local storage to use it later
    localStorage.setItem("letz-create-user-name", name);
    localStorage.setItem("letz-create-user-phone", phone);
    navigate("/auth/select-category");
  };

  return (
    <>
      <Row className="mt-2">
        <Row>
          <Col>
            {/* Changed the Button to a span */}
            <span
              className="ms-1 app-login-influencer-back-button"
              onClick={handleBackClick}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </span>
          </Col>
        </Row>
        <Row>
          <h2 className="app-login-h2 crimson-pro-thin ms-3 mt-1">
            Please enter your name
          </h2>
          <p className="app-login-p poppins-light ms-3 d-flex">
            You are just one click away
          </p>
          <Tabs className="ms-2">
            <Tab eventKey="influencer" title="I want to create content" />
          </Tabs>
        </Row>
        <Row className="mx-auto">
          <Col className="mt-2 d-flex align-items-center app-login-influencer-verified">
            {phone}
            <Col className="text-muted d-flex justify-content-end align-items-center">
              {" "}
              Verified <FontAwesomeIcon icon={faCheck} />
            </Col>
          </Col>
        </Row>
        <Form>
          <Form.Group>
            <Form.Control
              className="app-login-form-input app-login-influencer-form-input mt-2"
              type="text"
              placeholder="Your name here..."
              value={name}
              onChange={(e) => setName(e.target.value)}
              isInvalid={nameErr}
            />
            <Form.Control.Feedback type="invalid">
              {nameErr}
            </Form.Control.Feedback>
          </Form.Group>
          <Row className="mt-3 justify-content-center">
            <Button
              className="app-login-button poppins-semibold"
              variant="primary"
              type="submit"
              onClick={handleSubmit}
            >
              Next
            </Button>
          </Row>
        </Form>
      </Row>
    </>
  );
};

export default InfluencerName;
