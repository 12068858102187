import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Bootstrap components
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";

// User defined components
import Appbar from "../../components/Appbar";
import axiosInstance from "../../config/Axios";

const BlogDetail = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axiosInstance.get(`/blog/${slug}`);
        const data = response.data;
        setBlog(data);
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    };

    fetchBlog();
  }, [slug]);

  if (!blog) return null;

  return (
    <>
      <Appbar />
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="mt-5">
            <Breadcrumb>
              <Breadcrumb.Item className="app-link" href="/blog">
                Blog
              </Breadcrumb.Item>
              <Breadcrumb.Item active href={`/blog/${blog.slug}`}>
                {blog.title}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
            <img
              src="https://via.placeholder.com/600x250"
              className="card-img-top"
              alt="Placeholder"
            />
            <div className="mt-5">
              <h1 className="app-h1">{blog.title}</h1>
              <p>
                <small>{new Date(blog.createdAt).toLocaleDateString()}</small>
              </p>
              <p>{blog.content}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BlogDetail;
