import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// Bootstrap components
import { Container } from "react-bootstrap";

// User defined components
import Register from "./components/Register";
import Login from "./components/Login";
import SelectCategory from "./components/SelectCategory";
import ForgotPassword from "./components/ForgotPassword";

export function AuthPage() {
  return (
    <>
      <Container className="g-0">
        <Routes>
          <Route path="/auth/*" element={<Navigate to="/auth/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/Select-Category" element={<SelectCategory />} />
        </Routes>
      </Container>
    </>
  );
}
export default AuthPage;
