import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { BrowserRouter as Router } from "react-router-dom";

// Bootstrap components
import { ThemeProvider } from "react-bootstrap";

// User defined components
import ErrorBoundary from "./components/ErrorBoundary";
import { PublicRoutes } from "./routes/PublicRoutes";
import { PrivateRoutes } from "./routes/PrivateRoutes";

// Stylesheets
import "./assets/css/App.css";
import { useSelector } from "react-redux";

const initGA = (trackingID) => {
  ReactGA.initialize(trackingID);
};

const logPageView = () => {
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

export const withAnalytics = (WrappedComponent) => {
  return (props) => {
    useEffect(() => {
      logPageView();
    }, []);

    return <WrappedComponent {...props} />;
  };
};

export const App = () => {
  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);

  useEffect(() => {
    initGA("G-NZ2Q4GX4MQ");
    logPageView();
    window.addEventListener("popstate", logPageView);
  }, []);

  return (
    <>
      <ThemeProvider
        breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
        minBreakpoint="xxs"
      >
        <ErrorBoundary>
          <Router>{isLoggedIn ? <PrivateRoutes /> : <PublicRoutes />}</Router>
        </ErrorBoundary>
      </ThemeProvider>
    </>
  );
};

export default App;
