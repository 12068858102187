import { useEffect, useState } from "react";

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({ width: window.innerWidth, height: window.innerHeight });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 576);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};

export const useIsTablet = () => {
  const [isTablet, setIsTablet] = useState(
    window.innerWidth >= 576 && window.innerWidth < 992
  );

  useEffect(() => {
    function handleResize() {
      setIsTablet(window.innerWidth >= 576 && window.innerWidth < 992);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isTablet;
};

export const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth >= 992 && window.innerWidth < 1200
  );

  useEffect(() => {
    function handleResize() {
      setIsDesktop(window.innerWidth >= 992 && window.innerWidth < 1200);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isDesktop;
};

export const useIsLargeDesktop = () => {
  const [isLargeDesktop, setIsLargeDesktop] = useState(
    window.innerWidth >= 1200
  );

  useEffect(() => {
    function handleResize() {
      setIsLargeDesktop(window.innerWidth >= 1200);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isLargeDesktop;
};
