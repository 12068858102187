import { combineReducers } from "redux";

// Reducers
import authReducer from "../modules/auth/AuthReducer";
import jobReducer from "../modules/job/JobReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  jobs: jobReducer,
});

export default rootReducer;
