import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useJwt } from "react-jwt";

// Firebase components
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

// Bootstrap components
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  Card,
  Tabs,
  Tab,
  InputGroup,
} from "react-bootstrap";

// Fontawesome components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

// User defined components
import { actionCreators } from "../AuthReducer";
import axiosInstance from "../../../config/Axios";
import { useIsMobile } from "../../../config/Screen";
import { firebaseAuth } from "../../../config/Firebase";
import LoginInfluencer from "./LoginInfluencer";
import InfluencerName from "./InfluencerName";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  // Active Pill state variable
  const [key, setKey] = useState("influencer");

  // Influencer state variables
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
    otp5: "",
    otp6: "",
  });

  // Brand state variables
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // Add error state variable here
  const [phoneErr, setPhoneErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passErr, setPassErr] = useState("");

  // Add OTP state variables here
  const [isPhoneSubmit, setIsPhoneSubmit] = useState(false);
  const [isCorrectOTP, setIsCorrectOTP] = useState(false);
  const [otpErr, setOtpErr] = useState("");

  // Add new user state variable here
  const [isNewUser, setIsNewUser] = useState(false);

  // Add token state variable here
  const [token, setToken] = useState("");

  const { decodedToken, isExpired } = useJwt(
    token,
    process.env.REACT_APP_JWT_SECRET
  );

  const phoneRef = useRef();

  useEffect(() => {
    phoneRef.current = phone;
  }, [phone]);

  useEffect(() => {
    if (token && !isExpired) {
      const userId = decodedToken?._id;
      console.log("Decoded token:", decodedToken);
      console.log("User ID:", userId);
      dispatch(actionCreators.login(token, userId));
      const redirectAfterLogin = localStorage.getItem("redirectAfterLogin");
      if (redirectAfterLogin) {
        localStorage.removeItem("redirectAfterLogin");
        navigate(redirectAfterLogin);
      } else {
        navigate("/feed");
      }
    }
  }, [token, isExpired, decodedToken, dispatch, navigate]);

  useEffect(() => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      firebaseAuth,
      "send-otp-button",
      {
        size: "invisible",
        callback: function (response) {
          console.log("Recaptcha response:", response);
          onSignInSubmit();
        },
        "expired-callback": function () {
          alert("Recaptcha expired. Please try again.");
        },
        "error-callback": function (error) {
          console.error("Recaptcha error:", error);
          alert("An error occurred while verifying Recaptcha.");
        },
      }
    );
    window.recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });
  }, []);

  const onSignInSubmit = async () => {
    if (!phoneRef.current) {
      setPhoneErr("Phone number is required.");
      // Reset the reCAPTCHA
      const grecaptcha = window.grecaptcha;
      grecaptcha.reset(window.recaptchaWidgetId);
      return;
    }

    setPhoneErr("");

    if (phoneRef.current.length !== 10) {
      setPhoneErr("Phone number must be 10 digits.");
      // Reset the reCAPTCHA
      const grecaptcha = window.grecaptcha;
      grecaptcha.reset(window.recaptchaWidgetId);
      return;
    }

    setPhoneErr("");

    const phoneNumber = `+91${phoneRef.current}`;
    const appVerifier = window.recaptchaVerifier;
    console.log("Phone number:", phoneNumber);

    try {
      console.log("Recaptcha verified. Sending OTP.");
      const confirmationResult = await signInWithPhoneNumber(
        firebaseAuth,
        phoneNumber,
        appVerifier
      );
      console.log("OTP sent successfully:", confirmationResult);
      window.confirmationResult = confirmationResult;
      setIsPhoneSubmit(true);
    } catch (error) {
      console.error("Error sending OTP:", error);
      const grecaptcha = window.grecaptcha;
      grecaptcha.reset(window.recaptchaWidgetId);

      if (error.code === "auth/too-many-requests") {
        alert("Too many requests. Please try again later.");
      } else if (error.code === "auth/invalid-phone-number") {
        setPhoneErr("Invalid phone number.");
      } else {
        console.log("Error code:", error.code);
        alert("Something went wrong.");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const enteredOTP = Object.values(otp).join("");
    const confirmationResult = window.confirmationResult;
    console.log("Confirmation result:", confirmationResult);
    try {
      const result = await confirmationResult.confirm(enteredOTP);
      console.log("OTP confirmed successfully:", result);
      window.confirmationResult = null;
      setIsCorrectOTP(true);
    } catch (error) {
      console.error("Error confirming OTP:", error);
      if (error.code === "auth/invalid-verification-code") {
        alert("Invalid OTP. Please try again.");
        setOtpErr("Invalid OTP. Please try again.");
        setIsCorrectOTP(false);
      } else if (error.code === "auth/code-expired") {
        alert("OTP expired. Please resend OTP.");
        setOtpErr("OTP expired. Please resend OTP.");
        setIsCorrectOTP(false);
      } else {
        alert("Something went wrong.");
        setIsCorrectOTP(false);
      }
      return;
    }

    try {
      // Get ID token
      const idToken = await firebaseAuth.currentUser.getIdToken();
      console.log("Login ID token:", idToken);

      const phoneNumber = `+91${phoneRef.current}`;

      // Check phone number exists in the database
      const response = await axiosInstance.post(
        "/oauth/validate/phone",
        { phoneNumber },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (response.status === 204) {
        console.log("Phone number not found. Redirecting to InfluencerName.");
        setTimeout(() => {
          setIsNewUser(true);
        }, 2000); // 2 seconds in milliseconds
      }

      if (response.status === 200) {
        const data = response.data;
        console.log("Phone number found. Data:", data);

        // Set token state variable
        setToken(data.token);
      }
    } catch (error) {
      console.error("Error confirming OTP:", error);
      alert("An error occurred while confirming OTP.");
    }
  };

  const handleChange = (e) => {
    setOtp({ ...otp, [e.target.name]: e.target.value });
  };

  const handleEmailLogin = async (e) => {
    // Handle sign in logic here
    e.preventDefault();
    // Check if email is valid
    if (!email) {
      setEmailErr("Email is required.");
      return;
    }

    setEmailErr("");

    // Check if email is valid
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    if (!isValidEmail) {
      setEmailErr("Invalid email address.");
      return;
    }

    setEmailErr("");

    // Check if password is valid
    if (!password) {
      setPassErr("Password is required.");
      return;
    }

    // If email and password are valid, clear the error and continue with login
    setPassErr("");

    try {
      const response = await axiosInstance.post(
        "/auth/login/email",
        { email, password },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status !== 200) {
        alert(response.data.message);
        return;
      }

      const data = response.data;
      console.log("Login response:", data);

      // Set token state variable
      setToken(data.token);

      // Reset form fields
      setEmail("");
      setPassword("");
    } catch (error) {
      console.error("Login error:", error);
      alert("An error occurred during login.");
    }
  };

  function loginHeader() {
    return (
      <Row>
        <Col>
          <h2 className="app-login-h2 crimson-pro-thin">Welcome, login</h2>
          <p className="app-login-p poppins-light">
            Empowering individuals and brands
          </p>
        </Col>
      </Row>
    );
  }

  function influencerTab() {
    return (
      <Tab
        eventKey="influencer"
        title={key === "influencer" ? "I am an Influencer" : "Influencer"}
      >
        <Row>
          <Form>
            <Form.Group controlId="formPhone">
              <Form.Control
                className="app-login-form-input"
                type="tel"
                placeholder="Mobile Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                isInvalid={!!phoneErr}
              />
              <Form.Control.Feedback type="invalid">
                {phoneErr}
              </Form.Control.Feedback>
            </Form.Group>
            <Row className="mt-3 justify-content-center">
              <Button
                className="app-login-button poppins-semibold"
                variant="primary"
                type="submit"
                id="send-otp-button"
              >
                Send OTP
              </Button>
            </Row>
            {/* <Row className="mt-3">
              <p className="app-login-footer g-0 mb-0">
                Don't have an account?{" "}
                <Link to="/auth/register">Create New</Link>
              </p>
            </Row> */}
          </Form>
        </Row>
      </Tab>
    );
  }

  function brandTab() {
    return (
      <Tab
        eventKey="brand"
        title={key === "brand" ? "We are a Brand" : "Brand"}
      >
        <Row>
          <Form onSubmit={handleEmailLogin}>
            <Form.Group controlId="formEmail">
              <Form.Control
                className="mb-2 app-login-form-input"
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isInvalid={!!emailErr}
              />
              <Form.Control.Feedback type="invalid">
                {emailErr}
              </Form.Control.Feedback>
            </Form.Group>

            <InputGroup className="mb-1">
              <Form.Control
                className="app-login-form-input"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                aria-label="Password"
                aria-describedby="basic-addon2"
                isInvalid={!!passErr}
              />
              <InputGroup.Text
                id="basic-addon2"
                className="app-login-password-eye"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <FontAwesomeIcon icon={faEye} />
                ) : (
                  <FontAwesomeIcon icon={faEyeSlash} />
                )}
              </InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                {passErr}
              </Form.Control.Feedback>
            </InputGroup>

            <Row>
              <Col className="d-flex justify-content-end">
                <p className="app-login-link poppins-regular g-0 mb-0">
                  <Link to="/auth/forgot-password">Forgot password?</Link>
                </p>
              </Col>
            </Row>
            <Row className="mt-2 justify-content-center">
              <Button
                className="app-login-button poppins-semibold"
                variant="primary"
                type="submit"
              >
                Login
              </Button>
            </Row>
          </Form>
          <Row className="mt-3">
            <p className="app-login-footer g-0 mb-0">
              Don't have an account? <Link to="/auth/register">Create New</Link>
            </p>
          </Row>
        </Row>
      </Tab>
    );
  }

  if (isMobile) {
    return (
      <div className="app-login-sm-container">
        <Container>
          {/* <Row> */}
          {isPhoneSubmit ? (
            // Render LoginInfluencer if isPhoneSubmit
            <Row className="">
              {isNewUser ? (
                <InfluencerName phone={phone} />
              ) : (
                <LoginInfluencer
                  phone={phone}
                  otp={otp}
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  isCorrectOTP={isCorrectOTP}
                  otpErr={otpErr}
                />
              )}
            </Row>
          ) : (
            <>
              <Row>
                <Row className="mt-5">{loginHeader()}</Row>
              </Row>
              <Row>
                <Tabs
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-2"
                  id="login"
                >
                  {influencerTab()}
                  {process.env.REACT_APP_ENV === "development"
                    ? brandTab()
                    : null}
                </Tabs>
              </Row>
            </>
          )}
          {/* </Row> */}
        </Container>
      </div>
    );
  } else {
    return (
      <>
        <Container className="app-login-container">
          <Row className="justify-content-center">
            {isPhoneSubmit ? (
              // Render LoginInfluencer if isPhoneSubmit
              <Card className="app-login-card">
                {isNewUser ? (
                  <InfluencerName phone={phone} />
                ) : (
                  <LoginInfluencer
                    phone={phone}
                    otp={otp}
                    handleSubmit={handleSubmit}
                    handleChange={handleChange}
                    isCorrectOTP={isCorrectOTP}
                    otpErr={otpErr}
                  />
                )}
              </Card>
            ) : (
              <Card className="app-login-card">
                {loginHeader()}
                <Row>
                  <Tabs
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    id="login"
                    className="mb-2"
                  >
                    {influencerTab()}
                    {process.env.REACT_APP_ENV === "development"
                      ? brandTab()
                      : null}
                  </Tabs>
                </Row>
              </Card>
            )}
          </Row>
        </Container>
      </>
    );
  }
};

export default Login;
