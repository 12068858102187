import axios from "axios";

// Import the persisted reducer
import { store } from "./Store";

// Load environment variables from .env file
// require("dotenv").config(); // Import dotenv to load environment variables

// Get the backend server IP address from the .env file
const backendServerIP = process.env.REACT_APP_BACKEND_SERVER_IP;
const backendServerPort = process.env.REACT_APP_BACKEND_SERVER_PORT;
let HTTP_BASE_URL = "";

if (process.env.REACT_APP_ENV === "development") {
  HTTP_BASE_URL = `http://${backendServerIP}:${backendServerPort}/api/v1`;
} else {
  HTTP_BASE_URL = `https://${backendServerIP}:${backendServerPort}/api/v1`;
}

const axiosInstance = axios.create({
  baseURL: HTTP_BASE_URL, // Set the base URL
  timeout: 5000, // Set the timeout for requests
  headers: {
    "Content-Type": "application/json", // Set the default content type
  },
});

// Add authorization logic here
axiosInstance.interceptors.request.use((config) => {
  // Check if authorization token exists in persisted reducer and add it to the request headers
  const auth = store.getState().auth;
  if (auth && auth.token) {
    const authToken = auth.token;
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  return config;
});

export default axiosInstance;
