import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Bootstrap components
import { Container, Row, Col, Form, Button } from "react-bootstrap";

// User defined components
import Appbar from "../../components/Appbar";
import axiosInstance from "../../config/Axios";

// User can now navigate to the blog detail page by clicking on the "Read More" button
const BlogFeed = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axiosInstance.get("/blog"); // Replace with your server endpoint
        const data = response.data;
        setBlogs(data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <>
      <Appbar />
      <Container>
        <Row>
          <Col lg={8} md={8} sm={12} xs={12} className="mt-sm-5 mt-xs-5">
            <Row>
              <Col>
                <h1 className="app-h1">Latest Articles</h1>
                <hr />
              </Col>
            </Row>
            <Row>
              {blogs.map((blog) => (
                <Col
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="mb-3"
                  key={blog.slug}
                >
                  <div className="card">
                    <img
                      src="https://via.placeholder.com/600x250"
                      className="card-img-top"
                      alt="Placeholder"
                    />
                    <div className="card-body">
                      <h2 className="card-title">{blog.title}</h2>
                      <p className="card-text">{blog.content}</p>
                      <Link className="app-link" to={`/blog/${blog.slug}`}>
                        Read More
                      </Link>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
          <Col
            lg={4}
            md={4}
            sm={12}
            xs={12}
            className="border-start mt-sm-5 mt-xs-5 app-border-sm-start"
          >
            <Row>
              <Col>
                <h1 className="app-h1">Most Popular</h1>
                <hr />
              </Col>
            </Row>
            {blogs.map((blog) => (
              <Row key={blog.slug}>
                <Col className="mb-2 me-auto">
                  <img src="https://via.placeholder.com/50" alt="Placeholder" />
                  <div className="ms-2 d-inline-block">
                    <Link className="app-link" to={`/blog/${blog.slug}`}>
                      {blog.title}
                    </Link>
                  </div>
                </Col>
              </Row>
            ))}
            <Row className="mt-5">
              <Col className="me-auto">
                <h1>Newsletter</h1>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control type="email" placeholder="Enter email" />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Subscribe
                  </Button>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BlogFeed;
