import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

// Bootstrap components
import { Row, Col, Tab, Tabs, Button } from "react-bootstrap";

// Font Awesome icons used in the component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheck } from "@fortawesome/free-solid-svg-icons";

const LoginInfluencer = ({
  phone,
  otp,
  handleSubmit,
  handleChange,
  isCorrectOTP,
  otpErr,
}) => {
  const navigate = useNavigate();

  const [seconds, setSeconds] = useState(20);
  const [timerExpired, setTimerExpired] = useState(false);
  const inputRefs = useRef([]);
  const isDisabled =
    !otp.otp1 || !otp.otp2 || !otp.otp3 || !otp.otp4 || !otp.otp5 || !otp.otp6;

  useEffect(() => {
    const timer = setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        setTimerExpired(true);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [seconds]);

  const resetTimer = () => {
    setSeconds(20);
    setTimerExpired(false);
  };

  const handleResendOTP = () => {
    resetTimer();
    // Add logic to resend OTP here
  };

  function handleBackClick() {
    // Navigate back to the previous page in the history stack
    navigate("./login", { replace: true });
    window.history.back();
  }

  return (
    <>
      <Row>
        <Col>
          <Button className="ms-1 app-login-influencer-back-button">
            <FontAwesomeIcon icon={faArrowLeft} onClick={handleBackClick} />
          </Button>
        </Col>
      </Row>
      <Row>
        <h2 className="app-login-h2 crimson-pro-thin ms-3">Enter OTP</h2>
        <p className="app-login-p poppins-light ms-3 d-flex">
          We have sent an OTP on +91 {phone}
          <Link
            className="ms-1 text-decoration-none poppins-semibold"
            onClick={window.history}
          >
            Edit
          </Link>
        </p>
        <Tabs className="ms-2">
          <Tab eventKey="influencer" title="I want to create content"></Tab>
        </Tabs>
      </Row>
      <Row className="mx-auto">
        <Row className="text-center mt-2">
          <Col
            className={`ms-1 d-flex justify-center app-login-influencer-placeholder`}
          >
            {[1, 2, 3, 4, 5, 6].map((index) => (
              <input
                ref={(el) => (inputRefs.current[index - 1] = el)}
                key={index}
                className={`p-0 app-login-influencer-form-input me-1 text-center form-control ${
                  isCorrectOTP ? "app-login-influencer-correctotp-input" : ""
                } ${!isCorrectOTP && otpErr ? "app-login-incorrect-otp" : ""}`}
                type="text"
                value={otp[`otp${index}`]}
                name={`otp${index}`}
                maxLength={1}
                onChange={(e) => {
                  const currentInputIndex = index - 1;
                  const value = e.target.value;

                  // Update the OTP state
                  handleChange(e);

                  // Move focus to the next input if the current input has a value and it's not the last input
                  if (value !== "" && currentInputIndex < 5) {
                    inputRefs.current[currentInputIndex + 1].focus();
                  }
                }}
                onKeyDown={(e) => {
                  const currentInputIndex = index - 1;

                  if (
                    e.key === "Backspace" &&
                    e.target.value === "" &&
                    currentInputIndex > 0
                  ) {
                    // Move focus to the previous input if Backspace is pressed and the current input is empty
                    inputRefs.current[currentInputIndex - 1].focus();
                  } else if (e.key.match(/[0-9]/)) {
                    e.preventDefault();

                    if (e.target.value === "") {
                      // If the current input is empty, insert the value here
                      handleChange({
                        target: { name: `otp${index}`, value: e.key },
                      });
                      e.target.value = e.key;

                      // Move focus to the next input if the current input is not the last input
                      if (currentInputIndex < 5) {
                        inputRefs.current[currentInputIndex + 1].focus();
                      }
                    } else if (currentInputIndex < 5) {
                      // If the current input is not empty, move to the next input and insert the value
                      const nextInput =
                        inputRefs.current[currentInputIndex + 1];
                      nextInput.value = e.key;
                      handleChange({
                        target: { name: `otp${index + 1}`, value: e.key },
                      });
                      nextInput.focus();
                    }
                  }
                }}
              />
            ))}
          </Col>
          {otpErr && !isCorrectOTP && (
            <Row className="justify-content-center mt-2">
              <Col className="app-login-incorrect-otp-input text-danger">
                {otpErr}
              </Col>
            </Row>
          )}
        </Row>
        {!isCorrectOTP && (
          <Row className="mt-3 justify-content-center">
            <Button
              className="mb-2 ms-4 mx-auto w-100 app-login-button poppins-semibold"
              variant="primary"
              disabled={isDisabled}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Row>
        )}
        {!otpErr && isCorrectOTP && (
          <Row className="mt-3 justify-content-center">
            <Button
              className="ms-4 app-login-check-button poppins-semibold"
              variant="primary"
              disabled={isDisabled}
              onClick={handleSubmit}
            >
              <FontAwesomeIcon icon={faCheck} className="mt-2" />
            </Button>
          </Row>
        )}
        {!timerExpired && !isCorrectOTP && (
          <Row className="ms-1 justify-content-center text-center">
            <Col className="app-login-influencer-timer">
              00:{seconds < 10 ? `0${seconds}` : seconds} sec
            </Col>
          </Row>
        )}
        {timerExpired && !isCorrectOTP && (
          <Row className="ms-1 justify-content-center text-center">
            <Link
              className="app-login-influencer-timer poppins-semibold text-decoration-underline"
              onClick={handleResendOTP}
            >
              Resend OTP
            </Link>
          </Row>
        )}
      </Row>
    </>
  );
};

export default LoginInfluencer;
