import React, { useEffect, useState, useRef } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronRight,
  faCircleChevronLeft,
  faHeart,
  faCalendar,
  faArrowLeft,
  faHandshakeAngle,
  faSackDollar,
  faStar,
  faShareNodes,
  faBriefcase,
  faLink,
  faMarsAndVenus,
  faCalendarWeek,
  faNoteSticky,
  faClock,
  faIndianRupeeSign,
  faLocationDot,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";

import CategoryModel from "../../models/CategoryModel";
import { useIsMobile, useIsTablet } from "../../config/Screen";

// Bootstrap components
import {
  Container,
  Button,
  Row,
  Col,
  ProgressBar,
  Card,
  Image,
  Badge,
  Dropdown,
  Tabs,
  Tab,
  CardBody,
} from "react-bootstrap";
// import axios from "axios";
// User defined components
import axiosInstance from "../../config/Axios";
import Appbar from "../../components/Appbar";
const InfluencerJobPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [job, setJob] = useState(0);
  const [loading, setLoading] = useState(true);
  const [categoryLoading, setCategoryLoading] = useState(true);
  const [isApplicantsLoaded, setIsApplicantsLoaded] = useState(false);
  // const userId = useSelector(({ auth }) => auth.userId);
  const [clickedHearts, setClickedHearts] = useState([]);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const containerRef = useRef(null);
  const containerRef2 = useRef(null);
  const [jobs, setJobs] = useState([]);
  const userId = useSelector((state) => state.auth.userId);
  const token = useSelector((state) => state.auth.token);
  const [jobDetails, setJobDetails] = useState(true);
  const [applied, setApplied] = useState(false);
  const [key, setKey] = useState("JobDetails");
  const [applicants, setApplicants] = useState([]);
  const [isAuthor, setIsAuthor] = useState(false);
  const startDate = new Date(job.jobStartDate);
  const endDate = new Date(job.jobEndDate);
  const differenceInTime = endDate.getTime() - startDate.getTime();
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
  const originalTime = new Date(startDate);
  const [showSuccess, setShowSuccess] = useState(false);
  originalTime.setHours(originalTime.getHours());
  originalTime.setMinutes(originalTime.getMinutes());

  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);

  const formattedTime = originalTime.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const allCategoriesApplied =
    job.categoryBudget &&
    job.categoryBudget.every(
      (item) => item.applicants && item.applicants.includes(userId)
    );

  const allCategoriesApplicants =
    job.categoryBudget &&
    job.categoryBudget.reduce((totalApplicants, category) => {
      if (category.applicants) {
        return totalApplicants + category.applicants.length;
      } else {
        return totalApplicants;
      }
    }, 0);

  // Function to handle the click on the right chevron
  const handleNextClick = () => {
    // Scroll the container by 200px to the left
    containerRef.current.scrollLeft += 300;
  };
  const handlePrevClick = () => {
    // Scroll the container by 200px to the right
    containerRef.current.scrollLeft -= 300;
  };
  const handleHeartClick = (jobId) => {
    setClickedHearts((prevState) => ({
      ...prevState,
      [jobId]: !prevState[jobId], // Toggle the clicked state for the specific job
    }));
  };

  // Get the job ID from the URL
  useEffect(() => {
    // Fetch job details from the server
    const fetchJobs = async () => {
      try {
        if (loading) {
          const id = location.pathname.split("/")[2];
          const response = await axiosInstance.get(`/posts/${id}`);
          const data = response.data;
          setJob(data);
          if (data.author === userId) {
            setIsAuthor(true);
          } else {
            setIsAuthor(false);
          }
        }
      } catch (error) {
        console.error("Error fetching job:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, [location.pathname, loading]);

  useEffect(() => {
    // Fetch applicant details from the server
    const fetchApplicants = async () => {
      try {
        if (!job.categoryBudget) {
          return;
        }
        if (!isLoggedIn) {
          return;
        }
        const applicants = await Promise.all(
          job.categoryBudget.flatMap((item) =>
            item.applicants.map(async (applicantId) => {
              const response = await axiosInstance.get(
                `/posts/applicants/${applicantId}`
              );
              return response.data;
            })
          )
        );
        setApplicants(applicants);
        setIsApplicantsLoaded(true);
      } catch (error) {
        console.error("Error fetching applicants:", error);
      }
    };

    fetchApplicants();
  }, [job]);

  useEffect(() => {
    // Fetch categories from the server
    const fetchCategories = async () => {
      try {
        const response = await axiosInstance.get("/categories");
        const data = response.data;
        const categories = data.map((category) => new CategoryModel(category));

        // Save the categories into CategoryModel
        CategoryModel.save(categories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setCategoryLoading(false);
      }
    };
    fetchCategories();
  }, [categoryLoading]);

  function Cardapplied() {
    return (
      <Container className="app-influencerdetails-container">
        <Card className="app-influencerdetails-appliedcard">
          <Card.Body className="d-flex flex-column justify-content-center align-items-center">
            <Row className="justify-content-center align-items-center text-center">
              <FontAwesomeIcon
                icon={faCircleCheck}
                size="xl"
                style={{ color: "#d62976" }}
              />
            </Row>
            <Row className="justify-content-center align-items-center text-center mt-2">
              Applied Successfully
            </Row>
          </Card.Body>
        </Card>
      </Container>
    );
  }

  const handleCategoryClick = async (categoryId) => {
    if (!isLoggedIn) {
      const currentPath = location.pathname + location.search; // Get the current path and query string
      localStorage.setItem("redirectAfterLogin", currentPath);
      navigate("/auth/login");
    } else {
      try {
        const response = await axiosInstance.post(
          `/posts/${job._id}/apply`,
          {
            categoryId: categoryId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Response:", response); // Log the response for debugging
        if (response.status !== 200) {
          console.error("Error1:", response);
          alert("Error1: " + response.statusText);
          return;
        }
        setShowSuccess(true); // Show the Cardapplied component
        setTimeout(() => {
          navigate("/feed"); // Navigate to /feed after 3 seconds
        }, 1000);
      } catch (error) {
        console.error("Error2:", error);
        alert("Error2: " + error.message); // Log error message for better understanding
      }
    }
  };
  const handleApplicantClick = (applicant) => {
    console.log("Applicant:", applicant);
    navigate("/feed");
  };

  const handleApplicantSelect = async (applicantId, category) => {
    try {
      const response = await axiosInstance.post(
        `/posts/${job._id}/accept`,
        {
          applicant: applicantId,
          categoryId: category,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Response:", response);
      if (response.status !== 200) {
        console.error("Error1:", response);
        alert("Error1: " + response.statusText);
        return;
      }
      // console.log("Applicant selected:", applicant);
      alert("Applicant selected successfully!");
      navigate("/feed");
    } catch (error) {
      console.error("Error2:", error);
      alert("Error2: " + error.message); // Log error message for better understanding
    }
  };
  const handleBack = () => {
    window.history.back();
  };
  const getApplicantName = (applicantId) => {
    const applicant = applicants.find(
      (applicant) => applicant._id === applicantId
    );
    return applicant ? applicant.name : "Unknown";
  };
  const getCategoryListByApplicantId = (applicantId) => {
    const categoryList = [];
    const applicant = applicants.find(
      (applicant) => applicant._id === applicantId
    );

    if (!categoryLoading && applicant) {
      applicant.categories.forEach((categoryId) => {
        const category = CategoryModel.getNameById(categoryId);
        categoryList.push(category);
      });
    }

    return categoryList;
  };

  function jobDetailsCard() {
    if (!job.categoryBudget) {
      return null;
    }
    if (!categoryLoading) {
      return (
        <Row xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="">
          {job.categoryBudget.map((item, index) => (
            <Card
              key={index}
              className="app-jobpage-card1 mt-3 app-influencerjobpage-cardcollection"
            >
              <Row
                lg={4}
                md={4}
                sm={4}
                xs={4}
                className="app-jobpage-card1-h1 ms-3 mt-3 poppins-semibold"
              >
                {CategoryModel.getNameById(item.category)} Details
              </Row>

              <Row
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="ms-2 mt-4 poppins-regular"
              >
                <Col className="app-influencerjobpage-card1-h">
                  <FontAwesomeIcon icon={faNoteSticky} flip="vertical" />{" "}
                  Deliverables
                </Col>
                <Col className="poppins-regular app-influencerjobpage-card1-h2">
                  {item.deliveries ? item.deliveries : "-"}
                </Col>
              </Row>
              <Row
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="ms-2 mt-4 poppins-regular"
              >
                <Col className="app-influencerjobpage-card1-h">
                  <FontAwesomeIcon icon={faCalendarWeek} /> Deadlines
                </Col>
                <Col className="poppins-regular app-influencerjobpage-card1-h2">
                  {item.timelines
                    ? new Date(item.timelines).toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })
                    : "-"}
                </Col>
              </Row>
              <Row
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="ms-2 mt-4 poppins-regular"
              >
                <Col className="app-influencerjobpage-card1-h">
                  <FontAwesomeIcon icon={faMarsAndVenus} /> Gender
                </Col>
                <Col className="poppins-regular app-influencerjobpage-card1-h2">
                  {" "}
                  {item.gender ? item.gender : "-"}
                </Col>
              </Row>
              <Row
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="ms-2 mt-4 poppins-regular"
              >
                <Col className="app-influencerjobpage-card1-h">
                  <FontAwesomeIcon icon={faIndianRupeeSign} /> Budget
                </Col>
                <Col className="poppins-regular app-influencerjobpage-card1-h2">
                  {"₹"}
                  {job.isPaid
                    ? new Intl.NumberFormat("en-IN", {
                        maximumSignificantDigits: 3,
                      }).format(item.budget)
                    : "-"}
                </Col>
              </Row>
              <Row
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="ms-2 mt-4 poppins-regular"
              >
                <Col className="app-influencerjobpage-card1-h">
                  <FontAwesomeIcon icon={faBriefcase} /> Type of job
                </Col>
                <Col className="poppins-regular app-influencerjobpage-card1-h2">
                  {item.jobType === "project" ? "Project" : "Full Time"}
                </Col>
              </Row>
              <Row
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="ms-2 mt-4 poppins-regular mb-2"
              >
                <Col className="app-influencerjobpage-card1-h">
                  <FontAwesomeIcon icon={faLink} /> Reference link
                </Col>
                <Col className="poppins-regular app-influencerjobpage-card1-h2">
                  {job.reference.length > 0
                    ? job.reference.map((reference, index) => (
                        <div key={index}>{reference}</div>
                      ))
                    : "-"}
                </Col>
              </Row>
            </Card>
          ))}
        </Row>
      );
    }
  }
  const handleShareClick = () => {
    // Get the current URL
    const currentURL = window.location.href;

    // Construct the message with the current URL as a clickable link
    const message = `${currentURL}`;

    // Check if the Web Share API is available
    if (isMobile || isTablet) {
      // Open WhatsApp with the message
      window.open(
        `whatsapp://send?text=${encodeURIComponent(message)}`,
        "_blank"
      );
    } else {
      // Open WhatsApp or email option with the message for desktop
      window.open(
        `whatsapp://send?text=${encodeURIComponent(message)}`,
        "_blank"
      );
      // OR
      // window.open(`mailto:?subject=Check out this link&body=${encodeURIComponent(message)}`, '_blank');
    }
  };

  function card2() {
    // const currentTime = new Date().toISOString();
    // const jobEndDate = new Date(job.jobEndDate).toISOString();
    const currentTime = new Date().toISOString();
    let jobEndDate;

    try {
      jobEndDate = new Date(job.jobEndDate).toISOString();
    } catch (error) {
      console.error("Invalid job end date:", job.jobEndDate);
      jobEndDate = "Invalid date";
    }
    return (
      <>
        <Container>
          <Card className="app-jobpage-card2 app-influencerjobpage-cardcollection">
            <Card.Body>
              {(isMobile || isTablet) && (
                <Row lg={12} md={12} sm={12} xs={12} className="">
                  <Col className="d-flex align-items-center">
                    <FontAwesomeIcon icon={faArrowLeft} onClick={handleBack} />
                    <Card.Text className="ms-2">Jobdetails</Card.Text>
                  </Col>
                  <Col className="d-flex justify-content-end">
                    <FontAwesomeIcon
                      icon={faShareNodes}
                      onClick={handleShareClick}
                    />
                  </Col>
                </Row>
              )}
              <Row lg={12} md={12} sm={12} xs={12} className="mt-2">
                <Col
                  lg={10}
                  md={10}
                  sm={10}
                  xs={10}
                  className="d-flex align-items-center"
                >
                  <Image
                    src="https://via.placeholder.com/32"
                    roundedCircle
                    style={{ marginRight: "10px" }}
                  />

                  <Card.Text className="app-influencerjobpage-influencer poppins-medium">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {"  "}
                      By {job.authorName}
                      {/* <span className="ms-2">·</span>{" "}
                      <FontAwesomeIcon
                        className="ms-2"
                        icon={faStar}
                        style={{ color: "#FFD43B" }}
                      />{" "}
                      <span className="ms-1">4.8 (32)</span> */}
                    </div>
                  </Card.Text>
                </Col>
                <Col lg={1} md={1} sm={1} xs={1}>
                  {!isMobile && !isTablet && (
                    <Col className="">
                      <FontAwesomeIcon
                        icon={faShareNodes}
                        onClick={handleShareClick}
                      />
                    </Col>
                  )}
                </Col>
              </Row>
              <Row lg={12} md={12} sm={12} xs={12}>
                <Col
                  lg={11}
                  md={11}
                  sm={11}
                  xs={11}
                  className="app-influencerjobpage-req ms-2 mt-2 poppins-semibold"
                >
                  {job.title}
                </Col>
              </Row>
              <Row lg={12} md={12} sm={12} xs={12}>
                <Col
                  lg={11}
                  md={11}
                  sm={11}
                  xs={11}
                  className="app-influencerjobpage-about ms-2 poppins-regular"
                >
                  {job.content}
                </Col>
              </Row>
              <Row className="ms-2 mt-3 app-influencerjobpage-card1-h poppins-regular">
                Reporting Date
              </Row>
              <Col className="ms-2 mt-1 poppins-regular">
                <span className="app-influencerjobpage-card1-paid">
                  {differenceInDays} days ·{" "}
                  {new Date(job.jobStartDate).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    // year: "numeric",
                  })}{" "}
                  to{" "}
                  {new Date(job.jobEndDate).toLocaleDateString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })}
                </span>
              </Col>
              <Row className="ms-2 mt-3 app-influencerjobpage-card1-h poppins-regular">
                Reporting Time
              </Row>

              <Col className="ms-2 mt-1 poppins-regular">
                <span className=" app-influencerjobpage-card1-paid">
                  {formattedTime}
                </span>
              </Col>
              <Row className="ms-2 mt-3 app-influencerjobpage-card1-h poppins-regular">
                Location
              </Row>
              <Row>
                <Col className="ms-2 mt-1 poppins-regular">
                  <span className=" app-influencerjobpage-card1-paid">
                    {job.location}
                  </span>
                </Col>
              </Row>
              <Row className="position-relative">
                <Col className="ms-2 mt-3 app-influencerjobpage-card1-h poppins-regular">
                  Type of collaboration
                </Col>
              </Row>
              <Row>
                <Col className="ms-2 mt-1 poppins-regular">
                  <span
                    className={
                      job.isPaid
                        ? " app-influencerjobpage-card1-paid"
                        : " app-influencerjobpage-card1-paid"
                    }
                  >
                    {job.isPaid ? "Paid" : "UnPaid"}
                  </span>
                </Col>
                <Col className="text-muted poppins-regular position-absolute text-end mt-3">
                  <span className="app-influencerjobpage-card2-payment">
                    Applied{" "}
                    {allCategoriesApplicants > 0 ? allCategoriesApplicants : 0}
                  </span>
                </Col>
              </Row>
              {/* <Row xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                                <Col className="mt-4 d-flex text-muted poppins-regular app-influencerjobpage-card2-payment">
                                    <FontAwesomeIcon className="me-1" icon={faCircleCheck} size="lg" style={{ color: "#d62976" }} />
                                    Payment verified
                                </Col>
                            </Row> */}
              {!isAuthor && !isMobile && (
                <Row
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="mt-4 ms-2"
                >
                  <Col
                    xxl={4}
                    xl={4}
                    lg={4}
                    md={4}
                    sm={4}
                    onClick={() => handleHeartClick(job._id)}
                    className="app-jobpage-card2-btn poppins-semibold mb-2 ms-1"
                  >
                    <Row
                      className="p-0 mt-2"
                      onClick={() => handleHeartClick(job._id)}
                    >
                      <FontAwesomeIcon
                        className="p-0"
                        onClick={() => handleHeartClick(job._id)}
                        icon={faHeart}
                        color={clickedHearts[job._id] ? "#d62976" : "grey"}
                      />
                    </Row>
                    <Row className="app-jobpage-card2-save justify-content-center align-items-center">
                      Save
                    </Row>
                  </Col>
                  <Col xxl={7} xl={7} lg={7} md={7} sm={7} xs={7}>
                    <Row key={job._id}>
                      <Dropdown>
                        <Dropdown.Toggle
                          // variant="primary"
                          id="dropdown-basic"
                          className={`app-jobpage-card2-btn2 poppins-semibold mb-2 ${
                            allCategoriesApplied ||
                            (job.isActive === false &&
                              job.isCompleted === true) ||
                            jobEndDate < currentTime
                              ? "bg-secondary"
                              : "bg-primary"
                          }`}
                          disabled={
                            allCategoriesApplied ||
                            (job.isActive === false &&
                              job.isCompleted === true) ||
                            jobEndDate < currentTime
                          } // Disable the button if all categories are applied
                        >
                          {allCategoriesApplied ? "Applied" : "Apply Job"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {job &&
                            job.categoryBudget &&
                            job.categoryBudget.map((item, index) => {
                              // Check if userId exists in the applicants array
                              const userIdFound =
                                item.applicants &&
                                item.applicants.includes(userId);
                              // Render the category name only if userId is not found in applicants
                              if (!userIdFound) {
                                return (
                                  <Dropdown.Item
                                    key={index}
                                    className="custom-dropdown-item"
                                    onClick={() =>
                                      handleCategoryClick(item.category)
                                    }
                                  >
                                    {categoryLoading
                                      ? "Loading..."
                                      : CategoryModel.getNameById(
                                          item.category
                                        )}
                                  </Dropdown.Item>
                                );
                              } else {
                                return null;
                              }
                            })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Row>
                  </Col>
                </Row>
              )}
              {!isAuthor && isMobile && (
                <Row className="mt-4">
                  <Col
                    onClick={() => handleHeartClick(job._id)}
                    className="app-jobpage-card2-btn poppins-semibold mb-2 ms-1"
                  >
                    <Row
                      className="p-0 mt-2"
                      onClick={() => handleHeartClick(job._id)}
                    >
                      <FontAwesomeIcon
                        className="p-0"
                        onClick={() => handleHeartClick(job._id)}
                        icon={faHeart}
                        color={clickedHearts[job._id] ? "#d62976" : "grey"}
                      />
                    </Row>
                    <Row className="app-jobpage-card2-save justify-content-center align-items-center">
                      Save
                    </Row>
                  </Col>
                  <Col>
                    <Row key={job._id}>
                      <Dropdown>
                        <Dropdown.Toggle
                          // variant="primary"
                          id="dropdown-basic"
                          className={`app-jobpage-card2-btn2 poppins-semibold mb-2 ${
                            allCategoriesApplied ||
                            (job.isActive === false &&
                              job.isCompleted === true) ||
                            jobEndDate < currentTime
                              ? "bg-secondary"
                              : "bg-primary"
                          }`}
                          disabled={
                            allCategoriesApplied ||
                            (job.isActive === false &&
                              job.isCompleted === true) ||
                            jobEndDate < currentTime
                          } // Disable the button if all categories are applied
                        >
                          {allCategoriesApplied ? "Applied" : "Apply Job"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {job &&
                            job.categoryBudget &&
                            job.categoryBudget.map((item, index) => {
                              // Check if userId exists in the applicants array
                              const userIdFound =
                                item.applicants &&
                                item.applicants.includes(userId);
                              // Render the category name only if userId is not found in applicants
                              if (!userIdFound) {
                                return (
                                  <Dropdown.Item
                                    key={index}
                                    className="custom-dropdown-item"
                                    onClick={() =>
                                      handleCategoryClick(item.category)
                                    }
                                  >
                                    {categoryLoading
                                      ? "Loading..."
                                      : CategoryModel.getNameById(
                                          item.category
                                        )}
                                  </Dropdown.Item>
                                );
                              } else {
                                return null;
                              }
                            })}
                        </Dropdown.Menu>
                      </Dropdown>
                    </Row>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
          {!isAuthor && (
            <Row className="app-influencerjobpage-responsetime mx-auto align-items-center text-center">
              <Col className="mt-3 mb-1 poppins-regular">
                <FontAwesomeIcon icon={faClock} /> Response time is typically 48
                hours
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  }

  function cardcollection() {
    return (
      <>
        <Container className="">
          <Card className="mt-5 app-influencerjobpage-cardcollection">
            <Row
              lg={3}
              md={3}
              sm={3}
              xs={3}
              className="poppins-semibold mb-3 mt-3 ms-4"
            >
              Trending Jobs
            </Row>
            <Container
              className="app-influencerjobpage-scroller-container"
              ref={containerRef}
            >
              <Row
                className="app-influencerjobpage-scroller-cards d-flex"
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
              >
                {/* <Col className="position-absolute top-50 ms-3 mt-3 translate-middle-y">
                                    <FontAwesomeIcon icon={faCircleChevronLeft} onClick={handleNextClick} />
                                </Col> */}
                {jobs.map((job) => (
                  <Col
                    xxl={4}
                    xl={4}
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className=""
                  >
                    <Card key={job._id} className="mt-2 app-job-list-card">
                      <Card.Body>
                        <Row>
                          <Col
                            xxl={11}
                            xl={11}
                            lg={11}
                            md={11}
                            sm={11}
                            xs={11}
                            className="d-flex align-items-center"
                          >
                            <Image
                              className="me-2"
                              src="https://via.placeholder.com/32"
                              roundedCircle
                            />
                            <Card.Text className="app-job-list-author poppins-medium">
                              {job.authorName}
                            </Card.Text>
                          </Col>
                          <Col
                            xxl={1}
                            xl={1}
                            lg={1}
                            md={1}
                            sm={1}
                            xs={1}
                            className="text-end"
                          >
                            <Card.Text
                              onClick={() => handleHeartClick(job._id)}
                            >
                              <FontAwesomeIcon
                                icon={faHeart}
                                color={
                                  clickedHearts[job._id] ? "black" : "grey"
                                }
                              />
                            </Card.Text>
                          </Col>
                        </Row>
                        <Row lg={8} className="mt-3">
                          <Col>
                            <Card.Title className="mt-1 app-job-list-title poppins-semibold">
                              {job.title}
                            </Card.Title>
                          </Col>
                        </Row>
                        <Row lg={8}>
                          <Col>
                            <Card.Text className="app-job-list-content poppins-regular">
                              {job.content}
                            </Card.Text>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={9}>
                            <Row>
                              <Col>
                                {job.jobStartDate.split("T")[0] ===
                                job.jobEndDate.split("T")[0] ? (
                                  <Badge className="app-job-list-details poppins-regular me-2 mt-2">
                                    <FontAwesomeIcon icon={faCalendar} />{" "}
                                    {new Date(
                                      job.jobStartDate
                                    ).toLocaleDateString("en-GB", {
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                    })}
                                  </Badge>
                                ) : (
                                  <Badge className="app-job-list-details poppins-regular me-2 mt-2">
                                    <FontAwesomeIcon icon={faCalendar} />{" "}
                                    {new Date(
                                      job.jobStartDate
                                    ).toLocaleDateString("en-GB", {
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                    })}{" "}
                                    to{" "}
                                    {new Date(
                                      job.jobEndDate
                                    ).toLocaleDateString("en-GB", {
                                      day: "numeric",
                                      month: "short",
                                      year: "numeric",
                                    })}
                                  </Badge>
                                )}
                                <Badge className="app-job-list-details poppins-regular me-2 mt-2">
                                  <FontAwesomeIcon icon={faLocationDot} />{" "}
                                  {job.location}
                                </Badge>
                                {job.categoryBudget.map((item, index) => (
                                  <React.Fragment key={index}>
                                    <Badge className="app-job-list-details poppins-regular me-2 mt-2">
                                      {job.isPaid ? (
                                        <FontAwesomeIcon icon={faSackDollar} />
                                      ) : (
                                        <FontAwesomeIcon
                                          icon={faHandshakeAngle}
                                        />
                                      )}{" "}
                                      {categoryLoading
                                        ? "Loading..."
                                        : CategoryModel.getNameById(
                                            item.category
                                          )}
                                      {job.isPaid ? " | Budget is ₹" : null}
                                      {job.isPaid ? item.budget : null}
                                    </Badge>
                                  </React.Fragment>
                                ))}
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xxl={12}
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            className="text-end align-self-end"
                          >
                            <Link
                              // to={`/job/${job._id}`}
                              to={`/job/${job._id}`}
                              className="app-job-list-details-link poppins-semibold justify-content-center align-items-center"
                            >
                              View details{" "}
                              <FontAwesomeIcon icon={faCircleChevronRight} />
                            </Link>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>

                    <Col className="position-absolute top-50 end-0 me-3 mt-3 translate-middle-y">
                      <FontAwesomeIcon
                        icon={faCircleChevronRight}
                        onClick={handleNextClick}
                      />
                    </Col>
                    <Col className="position-absolute top-50 start-0 ms-2 mt-3 translate-middle-y">
                      <FontAwesomeIcon
                        icon={faCircleChevronLeft}
                        onClick={handlePrevClick}
                      />
                    </Col>
                  </Col>
                ))}
              </Row>
            </Container>
          </Card>
        </Container>
      </>
    );
  }
  function renderApplicants() {
    const currentTime = new Date().toISOString();
    return (
      <Container className="app-influencerdetails-applicants">
        {job.categoryBudget.flatMap((item, categoryIndex) => (
          <div key={categoryIndex} className="mb-5">
            <p className="app-job-list-badge w-100 p-1 d-flex justify-content-center mx-auto">
              {categoryLoading
                ? "Loading..."
                : CategoryModel.getNameById(item.category)}
            </p>
            {item.applicants.map((applicant, index) => (
              <Card
                key={index}
                className="app-influencerdetails-viewdet-applicants-card mb-2"
                // style={{ cursor: "pointer" }}
              >
                <CardBody>
                  <Row>
                    <Col
                      xxl={10}
                      xl={10}
                      lg={10}
                      md={10}
                      sm={10}
                      xs={10}
                      className="d-flex align-items-center"
                    >
                      <Link to={`/profile?user=${applicant}`}>
                        <div className="d-flex align-items-center">
                          <Image
                            className="me-2"
                            src="https://via.placeholder.com/32"
                            roundedCircle
                          />
                          {/* <Card.Text className="app-job-list-author poppins-medium">
                    {applicant.name} ⭐ 4.8
                  </Card.Text> */}
                          <Card.Text className="app-job-list-author poppins-medium">
                            {getApplicantName(applicant)}
                          </Card.Text>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {getCategoryListByApplicantId(applicant).map(
                        (category, categoryIndex) => (
                          <Badge
                            key={categoryIndex}
                            className="app-job-list-details poppins-regular me-2 mt-2"
                          >
                            {category}
                          </Badge>
                        )
                      )}
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center">
                      {/* <Button
                    className="app-job-create-CategoryCard"
                    style={{ display: hovered[index] ? "block" : "none" }}
                  >
                    Select
                  </Button> */}
                      {!item.isCompleted &&
                        job.isActive === true &&
                        job.isCompleted === false &&
                        job.jobEndDate > currentTime && (
                          <Link
                            onClick={() =>
                              handleApplicantSelect(applicant, item.category)
                            }
                          >
                            <FontAwesomeIcon
                              title="Select applicant"
                              className="app-influencerdetails-applicants-select"
                              icon={faCircleCheck}
                            ></FontAwesomeIcon>
                          </Link>
                        )}
                      {item.selectedApplicant === applicant && (
                        <FontAwesomeIcon
                          title="Selected applicant"
                          className="app-influencerdetails-applicants-select"
                          icon={faCircleCheck}
                          style={{ color: "#008000" }}
                        ></FontAwesomeIcon>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ))}
          </div>
        ))}
      </Container>
    );
  }

  if (loading && categoryLoading) {
    return (
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <ProgressBar
            animated
            now={100}
            label="Loading..."
            variant="dark"
            style={{ height: "3px" }}
          />
        </Col>
      </Row>
    );
  }
  if (isMobile || isTablet) {
    return (
      <>
        <Appbar />
        <Container>
          {showSuccess ? (
            <Cardapplied />
          ) : (
            <Row>
              {card2()}
              <Tabs className="mt-4 ms-3">
                {isAuthor && (
                  <Tab
                    eventKey="Applicants"
                    title={key === "Applicants" ? "Applicants" : "Applicants"}
                  >
                    {renderApplicants()}
                  </Tab>
                )}
                <Tab
                  eventKey="JobDetails"
                  title={key === "JobDetails" ? "Job Details" : "Job Details"}
                >
                  {jobDetailsCard()}
                </Tab>
              </Tabs>
            </Row>
          )}
          {/* {cardcollection()} */}
        </Container>
      </>
    );
  } else {
    return (
      <>
        <Appbar />
        <Container>
          {showSuccess ? (
            <Cardapplied />
          ) : (
            <Row
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="mt-4"
            >
              <Col lg={7} md={7} sm={7} xs={7} className="ms-2">
                <Tabs>
                  {isAuthor && (
                    <Tab eventKey="Applicants" title="Applicants">
                      {renderApplicants()}
                    </Tab>
                  )}
                  <Tab eventKey="JobDetails" title="Job Details">
                    {jobDetailsCard()}
                  </Tab>
                </Tabs>
              </Col>
              <Col className="ms-5">{card2()}</Col>
            </Row>
          )}
          {/* {cardcollection()} */}
        </Container>
        {/* {Cardapplied()} */}
      </>
    );
  }
};

export default InfluencerJobPage;
