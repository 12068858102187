class BudgetModel {
    constructor(budget) {
        this.id = budget._id;
        this.amount = budget.amount;
    }

    static save(budgets) {
        this.budgets = budgets;
    }

    static getAmountById(id) {
        const budget = this.budgets.find((budget) => budget.id === id);
        return budget ? budget.amount : null;
    }
}

export default BudgetModel;
