import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Action types
export const actionTypes = {
  ADD_POST: "ADD_POST",
  ADD_CATEGORY: "ADD_CATEGORY",
  ADD_LOCATION: "ADD_LOCATION",
};

// Import the necessary action types

// Define the initial state
const initialState = {
  posts: [],
  categories: [],
  locations: [],
};

// Define the job reducer function
const jobReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_POST:
      return {
        ...state,
        posts: [...state.posts, action.payload],
      };
    case actionTypes.ADD_CATEGORY:
      return {
        ...state,
        categories: action.payload,
      };
    case actionTypes.ADD_LOCATION:
      return {
        ...state,
        locations: action.payload,
      };
    default:
      return state;
  }
};

// Export the action creators
export const jobActionCreators = {
  addPost: (post) => {
    return { type: actionTypes.ADD_POST, payload: post };
  },
  addCategory: (category) => {
    return { type: actionTypes.ADD_CATEGORY, payload: category };
  },
  addLocation: (location) => {
    return { type: actionTypes.ADD_LOCATION, payload: location };
  },
};

const persistConfig = {
  key: "jobs",
  storage,
};

export default persistReducer(persistConfig, jobReducer);
