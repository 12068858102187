class CategoryModel {
  constructor(category) {
    this.id = category._id;
    this.name = category.name;
  }

  static save(categories) {
    this.categories = categories;
  }

  static getNameById(id) {
    const category = this.categories.find((category) => category.id === id);
    return category ? category.name : null;
  }
}

export default CategoryModel;
