import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

// Third party libraries
import moment from "moment";

// Bootstrap components
import {
  Container,
  Row,
  Col,
  Button,
  ProgressBar,
  Card,
} from "react-bootstrap";

// User defined components
import axiosInstance from "../../config/Axios";
import Appbar from "../../components/Appbar";

const JobPage = () => {
  const location = useLocation();
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(true);
  const userId = useSelector(({ auth }) => auth.userId);

  // Get the job ID from the URL
  useEffect(() => {
    // Fetch job details from the server
    const fetchJob = async () => {
      try {
        // Todo: Resolve warning React Hook useEffect has a missing dependency: 'location.pathname'.
        // Either include it or remove the dependency array
        const id = location.pathname.split("/")[2];
        const response = await axiosInstance.get(`/posts/${id}`);
        const data = response.data;
        setJob(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching job:", error);
      }
    };

    fetchJob();
  }, []);

  const handleApply = () => {
    // Logic to handle job application
    // Add the current user to the applicants list
    // You can use a state management library or API calls here
  };

  if (loading) {
    return (
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <ProgressBar
            animated
            now={100}
            label="Loading..."
            variant="warning"
            style={{ height: "3px" }}
          />
        </Col>
      </Row>
    );
  } else {
    return (
      <>
        {console.log(job)}
        <Appbar />
        <Container className="mt-5">
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title className="app-h1">{job.title}</Card.Title>
                  <Card.Text>{job.content}</Card.Text>
                  <Card.Text>{job.location}</Card.Text>
                  <Card.Text>
                    {new Date(job.jobDate).toLocaleDateString()}
                  </Card.Text>
                  <Card.Text>{job.authorName}</Card.Text>
                  <Card.Text className="text-muted justify-content-end">
                    {moment(job.createdAt).fromNow()}
                  </Card.Text>
                  {job.author === userId ? (
                    <div></div>
                  ) : (
                    <Button variant="primary" onClick={handleApply}>
                      Apply
                    </Button>
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body>
                  <Card.Title className="app-h1">Applicants</Card.Title>
                  {job.categoryBudget.map((category) => (
                    <div key={category._id}>
                      <Card.Title>{category.category}</Card.Title>
                      {category.applicants.map((applicant) => (
                        <Card.Text key={applicant._id}>
                          {applicant.name}
                        </Card.Text>
                      ))}
                    </div>
                  ))}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
};

export default JobPage;
// import React, { useEffect, useState } from "react";
// import { useLocation } from "react-router-dom";
// // import { useSelector } from "react-redux";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faYoutube, faSquareInstagram } from "@fortawesome/free-brands-svg-icons";
// import { faClapperboard, faHeart, faCircleCheck, faArrowLeft, faShareNodes, faIndianRupeeSign, faLocationDot } from "@fortawesome/free-solid-svg-icons";
// import CategoryModel from "./models/CategoryModel";
// import { useIsMobile, useIsTablet } from "../../config/Screen";
// // Third party libraries
// import moment from "moment";

// // Bootstrap components
// import {
//   Container,
//   Row,
//   Col,
//   Button,
//   ProgressBar,
//   Card,
//   Image
// } from "react-bootstrap";

// // User defined components
// import axiosInstance from "../../config/Axios";
// import Appbar from "../../components/Appbar";

// const JobPage = () => {
//   const location = useLocation();
//   const [job, setJob] = useState(0);
//   const [loading, setLoading] = useState(true);
//   // const userId = useSelector(({ auth }) => auth.userId);
//   const [clickedHearts, setClickedHearts] = useState([]);
//   const isMobile = useIsMobile();
//   const isTablet = useIsTablet();

//   const handleHeartClick = (jobId) => {
//     setClickedHearts((prevState) => ({
//       ...prevState,
//       [jobId]: !prevState[jobId], // Toggle the clicked state for the specific job
//     }));
//   };
//   // Get the job ID from the URL
//   useEffect(() => {
//     // Fetch job details from the server
//     const fetchJob = async () => {
//       try {
//         // Todo: Resolve warning React Hook useEffect has a missing dependency: 'location.pathname'.
//         // Either include it or remove the dependency array

//         const id = location.pathname.split("/")[2];
//         const response = await axiosInstance.get(`/posts/${id}`);
//         const data = response.data;
//         setJob(data);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching job:", error);
//       }
//     };

//     fetchJob();
//   }, [location.pathname]);

//   useEffect(() => {
//     // Fetch categories from the server
//     const fetchCategories = async () => {
//       try {
//         const response = await axiosInstance.get("/categories");
//         const data = response.data;
//         const categories = data.map((category) => new CategoryModel(category));
//         // Save the categories into CategoryModel
//         CategoryModel.save(categories);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching categories:", error);
//       }
//     };
//     fetchCategories();
//   }, []);

//   const handleBack = () => {
//     window.history.back();
//   }

//   function card1() {
//     return (
//       <Row xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
//         <Card className="app-jobpage-card1 mt-3">
//           <Row lg={4} md={4} sm={4} xs={4} className="app-jobpage-card1-h1 ms-3 mt-4 poppins-semibold">
//             Content requirement
//           </Row>
//           {!isMobile && !isTablet && (<Row className="ms-1 mt-4">
//             <Col lg={2} md={2} sm={2} xs={2} className="app-jobpage-card1-h2 poppins-medium"><FontAwesomeIcon className="me-1" icon={faClapperboard} size="lg" />5 Reels</Col>
//             <Col lg={4} md={4} sm={4} xs={4} className="app-jobpage-card1-h2 poppins-medium"><FontAwesomeIcon className="me-1" icon={faSquareInstagram} size="lg" style={{ color: "#d62976", }} />5 instagram Stories</Col>
//             <Col lg={4} md={4} sm={4} xs={4} className="app-jobpage-card1-h2 poppins-medium"><FontAwesomeIcon icon={faYoutube} size="lg" style={{ color: "#fa0505", }} /> 3 Youtube Videos</Col>
//           </Row>)}
//           {isMobile && isTablet && (
//             <>
//               <Row lg={12} md={12} sm={12} xs={12} className="ms-1 mt-4">
//                 <Col className="app-jobpage-card1-h2 poppins-medium"><FontAwesomeIcon className="me-1" icon={faClapperboard} size="lg" />5 Reels</Col>
//                 <Col className="app-jobpage-card1-h2 poppins-medium"><FontAwesomeIcon className="me-1" icon={faSquareInstagram} size="lg" style={{ color: "#d62976", }} />5 instagram Stories</Col>
//               </Row>
//               <Row lg={12} md={12} sm={12} xs={12} className="ms-1 mt-2">
//                 <Col className="app-jobpage-card1-h2 poppins-medium"><FontAwesomeIcon icon={faYoutube} size="lg" style={{ color: "#fa0505", }} /> 3 Youtube Videos</Col>
//               </Row></>)}
//           {!isMobile && (
//             <Row className="ms-0 mt-4">
//               <Col lg={5} md={5} sm={5} xs={5} className="app-jobpage-card1-h3 poppins-regular text-muted">Followers</Col>
//               <Col lg={5} md={5} sm={5} xs={5} className="app-jobpage-card1-h3 poppins-regular text-muted">Engagement</Col>
//             </Row>)}
//           <Row lg={12} md={12} sm={12} xs={12} className="d-flex ms-0 ">
//             {isMobile && (<Col lg={5} md={5} sm={5} xs={5} className="app-jobpage-card1-h3 poppins-regular text-muted mt-4">Followers</Col>)}
//             <Col lg={5} md={5} sm={5} xs={5} className="mt-2">
//               <div className="app-jobpage-progressbar progress">
//                 <div className="progress-bar bg-dark" style={{ width: '20%' }}></div>
//               </div>
//             </Col>
//             {isMobile && (<Col lg={5} md={5} sm={5} xs={5} className="app-jobpage-card1-h3 poppins-regular text-muted mt-4" >Engagement</Col>)}
//             <Col className="mt-2" lg={5} md={5} sm={5} xs={5}>
//               <div className="app-jobpage-progressbar progress">
//                 <div className="progress-bar bg-dark" style={{ width: '10%' }}></div>
//               </div>
//             </Col>
//           </Row>
//           <Row lg={12} md={12} sm={12} xs={12} className="app-jobpage-card1-h4 poppins-regular text-muted mt-4 ms-2">Hashtags and mentions</Row>
//           <Row className="mt-2">
//             <Col className="ms-2">
//               <Button
//                 className="app-job-list-badge poppins-regular mb-2 me-2"
//               >
//                 #fashion
//               </Button>
//               <Button
//                 className="app-job-list-badge poppins-regular mb-2 me-2"
//               >
//                 #summerfashion
//               </Button>
//             </Col>
//           </Row>
//           <Row className="ms-2 mt-4 poppins-regular app-jobpage-card1-location text-muted">
//             Location
//           </Row>
//           {isMobile && (<Row className="mb-3 mt-3">
//             <Col className="d-flex ms-2"><FontAwesomeIcon icon={faLocationDot} /></Col>
//             <Col className="poppins-regular app-jobpage-card1-h5" >{job.location}</Col>
//             <Col className="poppins-regular app-jobpage-card1-h5" >{moment(job.createdAt).fromNow()}</Col>
//           </Row>)}
//           {!isMobile && (
//             <Row lg={12} md={12} sm={12} xs={12} className="mb-3 mt-3">
//               <Col xxl={1} xl={1} lg={1} md={1} sm={1} xs={1} className="d-flex ms-2"><FontAwesomeIcon icon={faLocationDot} /></Col>
//               <Col className="poppins-regular app-jobpage-card1-h5" xxl={2} xl={2} lg={2} md={2} sm={2} xs={2}>{job.location}</Col>
//               <Col className="poppins-regular app-jobpage-card1-h5" xxl={3} xl={3} lg={3} md={4} sm={3} xs={3}>{moment(job.createdAt).fromNow()}</Col>
//             </Row>)}
//         </Card>
//       </Row >

//     )
//   }
//   function card2() {
//     return (
//       <Card className="app-jobpage-card2 mt-3">

//         <Card.Body>
//           {(isMobile || isTablet) && (
//             <Row lg={12} md={12} sm={12} xs={12} className="">
//               <Col className="d-flex align-items-center" lg={10} md={10} sm={10} xs={10}>
//                 <FontAwesomeIcon icon={faArrowLeft} onClick={handleBack} />
//                 <Card.Text className="ms-2">Jobdetails</Card.Text>
//               </Col>
//               <Col md={1} sm={1} xs={1} className="d-flex justify-content-end">
//                 <FontAwesomeIcon icon={faShareNodes} />
//               </Col>
//             </Row>
//           )}
//           <Row lg={12} md={12} sm={12} xs={12} className="mt-2">
//             <Col lg={11} md={11} sm={11} xs={11} className="d-flex align-items-center">
//               <Image
//                 src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/
//                               NlyAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAANbSURBVHgB7ZpbiE1RGMf/
//                               g2jKZQqJcdkYCZNcIi9kSpS8eHTJyJQiJFPGi0vevKBJHpAZTXLJLTWlNI1EHtxLTcjMCUXKJcat3L6vtY59Op29vnVm9p
//                               yzzm796tfaZ69v7/a3d2fvb629AY/H4/F4PJ4CUYbeM5dszFq3hkzJm6KK3ETOI/uTv8lO8ix5DY6ymPybZWCx3e4c22V6HI4
//                               SkAehDvKHXq4QttmJMLGnZAO5hFxNPs/oa4HD8AF+togLyG4dfwe5T04LwqTHwVFsE96lY18iOhk+CR913GHESD8UnmW6vUi+
//                               ioj5RJ7XyzMQI8VIOM1rof8n+oBiJlwUfMJJJykJd0Dd0ZukwAGwgx8TtWQ1VDkYxUCyHWZm6XYjucIQN123cyz2WYUYmYzwm
//                               ei6TUIu4hUOyJtQV/gRuZ98GBHbRX4hZ8LMZair3ExeMMTVkSvJB+QemDlKjocFUsJ7yTFQydRAFQQSKaE/vY8nZKshLl2gfB
//                               DimK3kcKjjNCIlXKPbDbBLtlhctQ003aX5ZEyASvQGEoKUMOPylc0bU8K/dBtAHt+WDFLCHXp5CxKCVGkd0209ORsJQLpL8+C
//                               bn4WLyPvkSZiHdVxp7YOZQLdLySGGuPm6nRSxTx5Lt8FusjAv+P/bDDcrqz9Q1ZX1NFA+07QBuZkcGdG/HmrQfkb/nkoOyhE3
//                               jSwn32ijGK3lp0SnoZ9JQc2MfEMBsZ3Tatex9UJco467HtHP89gNCK/2aVhQysNDnrQ/AFX+MjzFK46akjAezhzQLJeCkzIBk
//                               H4tIz46k5LwWNvAJCQ8jFyol9ukYKnwGIXwRnAb7sE1whWExcwtaQMp4VXkIagpnh2wY4HQP1i3lUJspW4rIuKmQL2Uq9a/+
//                               Y6dQi/ZDjcrrGzPwVym/sd21tJFuMjh+v4IeQkxkXmFJQpdafUI6S7No6Uy2NfcBa1le0Lcj6XvcBz/binpxJ1weR6x71EE4
//                               kz4LTkC6psrExN1+wIlzgmox8g95J7W5XWtOuaZsC+OPaVj7yJ84+gUAcK3jF3kOqgD5fXb9Dru+wp5GFeHPN8KFgtOMIXo
//                               EpBPyFqL/fRZwnF8a5kNj035q7paqHkn9h35GOorPZtqbCjCQQbTbbmdx+PxeEqafykVL/gDRuTBAAAAAElFTkSuQmCC"
//               />

//               <Card.Text className="app-job-list-author poppins-semibold">
//                 {job.authorName}
//               </ Card.Text>
//             </Col>
//             {(!isMobile && !isTablet) && (
//               <Col lg={1} md={1} sm={1} xs={1} className="">
//                 <FontAwesomeIcon icon={faShareNodes} />
//               </Col>
//             )}
//           </Row>
//           <Row className="ms-2 mt-2">
//             <ul className="app-jobpage-job-title poppins-semibold">
//               <li>{job.title}</li>
//             </ul>
//           </Row>
//           <Row>
//             {job && job.categoryBudget && job.categoryBudget.map((item, index) => (
//               <Row key={index}  >
//                 <Row className="d-flex">
//                   <Col >
//                     <Card.Text className="app-job-list-category">
//                       {item.category}
//                     </Card.Text>
//                   </Col>
//                   <Col >
//                     {job.isPaid ? (
//                       <Card.Text className="app-job-list-category">
//                         Paid Promotion()
//                       </Card.Text>
//                     ) : 0}
//                   </Col>
//                 </Row>
//                 <Col className="app-jopage-card1-rupees poppins-semibold mt-2 mb-3"><FontAwesomeIcon icon={faIndianRupeeSign} /> {((item.budget) / 100) * 95} to {item.budget}</Col>
//               </Row>
//             ))}
//           </Row>
//           <hr></hr>
//           <Row xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="d-flex">
//             <Col className="d-flex text-muted poppins-regular app-jobpage-card2-req">
//               Required platform
//             </Col>
//             <Col className="d-flex text-muted poppins-regular app-jobpage-card2-payment mt-1">
//               <FontAwesomeIcon className="me-1" icon={faCircleCheck} size="lg" style={{ color: "#d62976" }} />
//               Payment verified
//             </Col>
//           </Row>
//           <Row className="mt-2">
//             <Col ><FontAwesomeIcon className="" icon={faSquareInstagram} size="lg" style={{ color: "#d62976", }} />
//               <FontAwesomeIcon className=" ms-2" icon={faYoutube} size="lg" style={{ color: "#fa0505", }} /> </Col>
//           </Row>
//           {!isMobile && !isTablet && (
//             <Row xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="mt-4">
//               <Button
//                 className="app-jobpage-card2-btn poppins-semibold mb-2 ms-2 me-2">
//                 <Row className="app-jobpage-card2-save mx-auto" xxl={5} xl={6} lg={6} md={6} sm={9} xs={9}>
//                   <Col><FontAwesomeIcon onClick={() => handleHeartClick(job._id)} icon={faHeart} color={clickedHearts[job._id] ? "black" : "grey"} /></Col> <Col className="d-flex align-items-center">
//                     Save
//                   </Col>
//                 </Row>
//               </Button>
//               <Button xxl={5} xl={5} lg={5} md={9} sm={9} xs={9}
//                 className="app-jobpage-card2-btn2 poppins-semibold mb-2">
//                 Apply Job
//               </Button>
//             </Row>
//           )}
//         </Card.Body>
//       </Card>
//     )
//   }
//   function card3() {
//     return (
//       <Card className="app-jobpage-card1 mt-3">
//         <Row className="app-jpb-page-card3-h1 poppins-semibold mt-4 ms-2 mb-4">Job overview</Row>
//         <Row className="ms-3">
//           <ul>
//             <li className="poppins-regular app-jobpage-card3-overview">Browse our collection and pick any 3 products that resonate with your style.</li>
//             <li className="poppins-regular app-jobpage-card3-overview">Let us know your selections, and we'll send them over to you!</li>
//             <li className="poppins-regular app-jobpage-card3-overview">Create 3 reels showcasing the products in your signature style</li>
//             <li className="poppins-regular app-jobpage-card3-overview">Share the love! Tag us, use our hashtags, and let your followers in on the Snitch experience.</li>
//             <li className="poppins-regular app-jobpage-card3-overview">Browse our collection and pick any 3 products that resonate with your style.</li>
//             <li className="poppins-regular app-jobpage-card3-overview">Browse our collection and pick any 3 products that resonate with your style.</li>
//             <li className="poppins-regular app-jobpage-card3-overview">Let us know your selections, and we'll send them over to you!</li>
//             <li className="poppins-regular app-jobpage-card3-overview">Create 3 reels showcasing the products in your signature style</li>
//             <li className="poppins-regular app-jobpage-card3-overview">Share the love! Tag us, use our hashtags, and let your followers in on the Snitch experience.</li>
//             <li className="poppins-regular app-jobpage-card3-overview">Browse our collection and pick any 3 products that resonate with your style.</li>
//           </ul>
//         </Row>
//         {(isMobile || isTablet) && (<Row xxl={12} xl={12} lg={12} md={12} sm={12} xs={12} className="mt-4">

//           <Button xxl={5} xl={5} lg={5} md={5} sm={5} xs={5}
//             className="app-jobpage-card2-btn poppins-semibold mb-2 ms-4">
//             <row className="app-jobpage-card2-heart"><FontAwesomeIcon onClick={() => handleHeartClick(job._id)} icon={faHeart} color={clickedHearts[job._id] ? "black" : "grey"} /> </row><row className="app-jobpage-card2-save">Save</row>
//           </Button>


//           <Button xxl={7} xl={7} lg={7} md={7} sm={7} xs={7}
//             className="app-jobpage-card2-btn2 poppins-semibold mb-2 ms-2">
//             Apply Job
//           </Button>

//         </Row>
//         )}
//       </Card>
//     )
//   }
//   if (loading) {
//     return (
//       <Row>
//         <Col lg={12} md={12} sm={12} xs={12}>
//           <ProgressBar
//             animated
//             now={100}
//             label="Loading..."
//             variant="warning"
//             style={{ height: "3px" }}
//           />
//         </Col>
//       </Row>
//     );
//   }
//   if (isMobile || isTablet) {
//     return (
//       <>

//         <Container>

//           <Row>
//             {card2()}
//             {card1()}
//             {card3()}
//           </Row>
//         </Container>
//       </>
//     )
//   }
//   else {
//     return (
//       <>
//         {/* {console.log(job)} */}
//         <Appbar />
//         <Container>
//           <Row lg={12} md={12} sm={12} xs={12} className="">
//             <Col lg={7} md={7} sm={7} xs={7} className="ms-2">
//               {card1()}
//               <Row>
//                 {card3()}
//               </Row>
//             </Col>
//             <Col className="ms-5 me-3">
//               {card2()}
//             </Col>
//           </Row>
//         </Container>
//       </>
//     );
//   }
// };

// export default JobPage;
