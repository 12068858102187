class LocationModel {
    constructor(location) {
        this.id = location._id;
        this.name = location.name;
    }

    static save(locations) {
        this.locations = locations;
    }

    static getNameById(id) {
        const location = this.locations.find((loc) => loc.id === id);
        return location ? location.name : null;
    }
}
export default LocationModel;