import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useJwt } from "react-jwt";

// Bootstrap components
import {
  Row,
  Col,
  Button,
  Container,
  Card,
  Tabs,
  Tab,
  Form,
  ProgressBar,
} from "react-bootstrap";

// User defined components
import { useIsMobile } from "../../../config/Screen";
import axiosInstance from "../../../config/Axios";
import { actionCreators } from "../AuthReducer";
import CategoryModel from "../../../models/CategoryModel";
import { firebaseAuth } from "../../../config/Firebase";

const SelectCategory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const [token, setToken] = useState(""); // [1] State variable to store the token
  const [loading, setLoading] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const { decodedToken, isExpired } = useJwt(
    token,
    process.env.REACT_APP_JWT_SECRET
  );

  useEffect(() => {
    if (token && !isExpired) {
      const userId = decodedToken?._id;
      console.log("Decoded token:", decodedToken);
      console.log("User ID:", userId);
      dispatch(actionCreators.login(token, userId));
      navigate("/feed");
    }
  }, [token, isExpired, decodedToken, dispatch, navigate]);

  useEffect(() => {
    // Fetch categories from the server
    const fetchCategories = async () => {
      try {
        // Get ID token
        const idToken = await firebaseAuth.currentUser.getIdToken();
        console.log("Categories ID token:", idToken);
        const response = await axiosInstance.get("/oauth/categories", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        });
        if (response.status !== 200) {
          console.error("Error fetching categories");
          return;
        }
        const data = response.data;
        const categories = data.map((category) => new CategoryModel(category)); // Assuming CategoryModel is a class representing the category data structure
        // Save the categories into CategoryModel
        CategoryModel.save(categories);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const handleCategoryClick = (category) => {
    const index = selectedCategories.indexOf(category);
    if (index === -1) {
      setSelectedCategories([...selectedCategories, category]);
    } else {
      setSelectedCategories(
        selectedCategories.filter((cat) => cat !== category)
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log("Selected categories:", selectedCategories);

    // Get ID token
    const idToken = await firebaseAuth.currentUser.getIdToken();
    console.log("ID token:", idToken);

    // Get name and phone from local storage
    const name = localStorage.getItem("letz-create-user-name");
    const phone = localStorage.getItem("letz-create-user-phone");

    try {
      const response = await axiosInstance.post(
        "/oauth/register/phone",
        {
          name: name,
          phoneNumber: phone,
          categories: selectedCategories,
          userType: "user",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (response.status !== 200) {
        console.error("Error registering user");
        alert(response);
        navigate("/auth/login");
        return;
      }

      const data = response.data;
      console.log("User registered:", data);

      // Set token state variable
      setToken(data.token);
    } catch (error) {
      console.error("Error registering user:", error);
      alert("Error registering user");
      navigate("/auth/login");
    }
  };

  function selectCategoryHeader() {
    return (
      <Row>
        <Col>
          <h2 className="app-category-h2 crimson-pro-thin">
            Select your Categories
          </h2>
          <p className="app-category-p poppins-light">
            Empowering individuals and brands
          </p>
        </Col>
      </Row>
    );
  }

  function selectCategoryForm() {
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formSelectCategory">
          <Form.Label className="app-select-category-menu-heading poppins-medium">
            How do you influence your audience?
          </Form.Label>
          <Row className="app-scrollable-categories">
            <Row className="mb-3">
              {CategoryModel.categories.map((category) => (
                <Form.Check
                  key={category.id}
                  className="app-category mt-3"
                  type="checkbox"
                  label={category.name}
                  id={category.id}
                  checked={selectedCategories.includes(category.id)}
                  onChange={() => handleCategoryClick(category.id)}
                />
              ))}
            </Row>
          </Row>
        </Form.Group>
        <Row className="mt-3 justify-content-center">
          <Button
            className="app-category-button poppins-semibold"
            variant="primary"
            type="submit"
          >
            Start Exploring
          </Button>
        </Row>
      </Form>
    );
  }

  if (loading) {
    return (
      <Row>
        <Col lg={12} md={12} sm={12} xs={12}>
          <ProgressBar
            animated
            now={100}
            label="Loading..."
            variant="warning"
            style={{ height: "3px" }}
          />
        </Col>
      </Row>
    );
  } else {
    if (isMobile) {
      return (
        <>
          <div className="app-category-sm-container">
            <Container>
              <Row>
                <Row className="mt-5">{selectCategoryHeader()}</Row>
              </Row>
              <Row>
                <Tabs className="mb-2" id="select-category">
                  <Tab eventKey="influencer" title="I want to create content">
                    {selectCategoryForm()}
                  </Tab>
                </Tabs>
              </Row>
            </Container>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Container className="app-category-container">
            <Row className="justify-content-center">
              <Card className="app-category-card">
                {selectCategoryHeader()}
                <Row>
                  <Tabs className="mb-2" id="select-category">
                    <Tab eventKey="influencer" title="I want to create content">
                      {selectCategoryForm()}
                    </Tab>
                  </Tabs>
                </Row>
              </Card>
            </Row>
          </Container>
        </>
      );
    }
  }
};

export default SelectCategory;
