import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useJwt } from "react-jwt";

// Bootstrap components
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  Card,
  Tabs,
  Tab,
  InputGroup,
} from "react-bootstrap";

// Fontawesome components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

// User defined components
import { actionCreators } from "../AuthReducer";
import axiosInstance from "../../../config/Axios";
import { useIsMobile } from "../../../config/Screen";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  // Active Pill state variable
  const [key, setKey] = useState("influencer");

  // Influencer state variables
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [phone, setPhone] = useState("");

  // Brand state variables
  const [brandname, setBrandName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // Add error state variable here
  const [fnameErr, setFnameErr] = useState("");
  const [lnameErr, setLnameErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [brandNameErr, setBrandNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passErr, setPassErr] = useState("");

  // Add token state variable here
  const [token, setToken] = useState("");

  const { decodedToken, isExpired } = useJwt(
    token,
    process.env.REACT_APP_JWT_SECRET
  );

  useEffect(() => {
    if (token && !isExpired) {
      const userId = decodedToken?._id;
      console.log("Decoded token:", decodedToken);
      console.log("User ID:", userId);
      dispatch(actionCreators.login(token, userId));
      navigate("/feed");
    }
  }, [token, isExpired, decodedToken, dispatch, navigate]);

  const handlePhoneRegister = (e) => {
    // Handle sign up logic here
    e.preventDefault();

    // Add first name validation here
    const isValidFname = firstname.length > 2;
    if (!isValidFname) {
      setFnameErr("First name must be at least 3 characters.");
      return;
    }

    setFnameErr("");

    // Add last name validation here
    const isValidLname = lastname.length > 2;
    if (!isValidLname) {
      setLnameErr("Last name must be at least 3 characters.");
      return;
    }

    setLnameErr("");

    // Add phone validation here
    const isValidPhone = phone.length === 10;
    if (!isValidPhone) {
      setPhoneErr("Invalid phone number.");
      return;
    }

    setFnameErr("");
    setLnameErr("");
    setPhoneErr("");
  };

  const handleEmailRegister = async (e) => {
    e.preventDefault();

    // Add brand name validation here
    const isValidBrandName = brandname.length > 2;
    if (!isValidBrandName) {
      setBrandNameErr("Brand name must be at least 3 characters.");
      return;
    }

    setBrandNameErr("");

    // Add email validation here
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    if (!isValidEmail) {
      setEmailErr("Invalid email address.");
      return;
    }

    setEmailErr("");

    // Check if password is valid
    if (!password) {
      setPassErr("Password is required.");
      return;
    }

    setPassErr("");

    try {
      // Send signup request
      const response = await axiosInstance.post(
        "/auth/register/email",
        {
          name: brandname,
          email,
          password,
          userType: "brand",
        },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status !== 200) {
        alert(response.data.message);
        return;
      }

      // Handle successful signup
      const data = response.data;
      console.log("User signed up successfully:", data);

      // Set token state variable
      setToken(data.token);

      // Reset form fields
      setFirstName("");
      setLastName("");
      setEmail("");
      setPhone("");
      setPassword("");
    } catch (error) {
      // Handle signup error
      console.error("Sign Up error:", error);
      alert("An error occurred during sign up.");
    }
  };

  function registerHeader() {
    return (
      <Row>
        <Col>
          <h2 className="app-register-h2 crimson-pro-thin">
            Create your account
          </h2>
          <p className="app-register-p poppins-light">
            Empowering individuals and brands
          </p>
        </Col>
      </Row>
    );
  }

  function influencerTab() {
    return (
      <Tab
        eventKey="influencer"
        title={
          key === "influencer" ? "I want to create content" : "Content Creator"
        }
      >
        <Row>
          <Form onSubmit={handlePhoneRegister}>
            <Form.Group controlId="formRegister">
              <Form.Control
                className="mb-2 app-register-form-input"
                type="name"
                placeholder="First Name"
                value={firstname}
                onChange={(e) => setFirstName(e.target.value)}
                isInvalid={!!fnameErr}
              />
              <Form.Control.Feedback type="invalid">
                {fnameErr}
              </Form.Control.Feedback>
              <Form.Control
                className="mb-2 app-register-form-input"
                type="name"
                placeholder="Last Name"
                value={lastname}
                onChange={(e) => setLastName(e.target.value)}
                isInvalid={!!lnameErr}
              />
              <Form.Control.Feedback type="invalid">
                {lnameErr}
              </Form.Control.Feedback>
              <Form.Control
                className="app-register-form-input"
                type="tel"
                placeholder="Mobile Number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                isInvalid={!!phoneErr}
              />
              <Form.Control.Feedback type="invalid">
                {phoneErr}
              </Form.Control.Feedback>
            </Form.Group>
            <Row className="mt-3 justify-content-center">
              <Button
                className="app-register-button poppins-semibold"
                variant="primary"
                type="submit"
              >
                Get OTP
              </Button>
            </Row>
            <Row className="mt-3">
              <p className="app-register-footer g-0 mb-0">
                I agree to <Link to="/">terms</Link> of use &{" "}
                <Link to="/">Privacy policy</Link>
              </p>
            </Row>
            <Row className="mt-3">
              <p className="app-register-footer g-0 mb-0">
                Have an account? <Link to="/auth/login">Login</Link>
              </p>
            </Row>
          </Form>
        </Row>
      </Tab>
    );
  }

  function brandTab() {
    return (
      <Tab
        eventKey="brand"
        title={key === "brand" ? "We are a Brand" : "Brand"}
      >
        <Row>
          <Form onSubmit={handleEmailRegister}>
            <Form.Group controlId="formRegister">
              <Form.Control
                className="mb-2 app-register-form-input"
                type="tel"
                placeholder="Brand Name"
                value={brandname}
                onChange={(e) => setBrandName(e.target.value)}
                isInvalid={!!brandNameErr}
              />
              <Form.Control.Feedback type="invalid">
                {brandNameErr}
              </Form.Control.Feedback>
              <Form.Control
                className="mb-2 app-register-form-input"
                type="name"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isInvalid={!!emailErr}
              />
              <Form.Control.Feedback type="invalid">
                {emailErr}
              </Form.Control.Feedback>
            </Form.Group>

            <InputGroup className="mb-1">
              <Form.Control
                className="app-register-form-input"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                aria-label="Password"
                aria-describedby="basic-addon2"
                isInvalid={!!passErr}
              />
              <InputGroup.Text
                id="basic-addon2"
                className="app-register-password-eye"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <FontAwesomeIcon icon={faEye} />
                ) : (
                  <FontAwesomeIcon icon={faEyeSlash} />
                )}
              </InputGroup.Text>
              <Form.Control.Feedback type="invalid">
                {passErr}
              </Form.Control.Feedback>
            </InputGroup>

            <Row className="mt-3 justify-content-center">
              <Button
                className="app-register-button poppins-semibold"
                variant="primary"
                type="submit"
              >
                Continue
              </Button>
            </Row>
            <Row className="mt-3">
              <p className="app-register-footer g-0 mb-0">
                I agree to <Link to="/">terms</Link> of use &{" "}
                <Link to="/">Privacy policy</Link>
              </p>
            </Row>
            <Row className="mt-3">
              <p className="app-register-footer g-0 mb-0">
                Have an account? <Link to="/auth/login">Login</Link>
              </p>
            </Row>
          </Form>
        </Row>
      </Tab>
    );
  }

  if (isMobile) {
    return (
      <>
        <div className="app-register-sm-container">
          <Container>
            <Row>
              <Row className="mt-5">{registerHeader()}</Row>
            </Row>
            <Row>
              <Tabs
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-2"
                id="login"
              >
                {influencerTab()}
                {process.env.REACT_APP_ENV === "development"
                  ? brandTab()
                  : null}
              </Tabs>
            </Row>
          </Container>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Container className="app-register-container">
          <Row className="justify-content-center">
            <Card className="app-register-card">
              {registerHeader()}
              <Row>
                <Tabs
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  id="login"
                  className="mb-2"
                >
                  {influencerTab()}
                  {process.env.REACT_APP_ENV === "development"
                    ? brandTab()
                    : null}
                </Tabs>
              </Row>
            </Card>
          </Row>
        </Container>
      </>
    );
  }
};

export default Register;
