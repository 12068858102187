import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Bootstrap components
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Image,
  Offcanvas,
} from "react-bootstrap";

// Fontawesome icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faHeart } from "@fortawesome/free-regular-svg-icons";

// User defined components
import { actionCreators } from "../modules/auth/AuthReducer";

// Appbar component
const Appbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector(({ auth }) => auth.isLoggedIn);
  const userId = useSelector(({ auth }) => auth.userId);

  const handleSignOut = () => {
    console.log("Signing out");
    dispatch(actionCreators.logout());
    navigate("/home");
  };

  return (
    <Navbar
      sticky="top"
      collapseOnSelect
      expand="md"
      className="bg-body-tertiary app-bar-nav p-0"
      id="appbar"
      
    >
      <Container>
        <div>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Brand
            href="https://letzcreate.in"
            className="app-appbar-title"
          >
            <Image src={require("../assets/img/logo.png")} />
          </Navbar.Brand>
        </div>
        <Navbar.Offcanvas
          id="responsive-navbar-nav"
          aria-labelledby="responsive-navbar-nav"
          placement="start"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Letz Create</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            {isLoggedIn && (
              <Nav className="ms-5 me-auto" activeKey={location.pathname}>
                {/* <Nav.Link href="/blog">Blog</Nav.Link> */}
                <Nav.Link href={isLoggedIn ? "/feed" : "/auth"}>
                  Find a Job
                </Nav.Link>
                <Nav.Link href={isLoggedIn ? "/create" : "/auth"}>
                  Create a Job
                </Nav.Link>
                <Nav.Link
                  href={isLoggedIn ? "/profile" : "/auth"}
                  active={
                    isLoggedIn &&
                    location.pathname === "/profile" &&
                    location.search === ""
                  }
                >
                  My Profile
                </Nav.Link>
              </Nav>
            )}
            {!isLoggedIn && (
              <Nav className="ms-5 me-auto" activeKey={location.pathname}>
                {/* <Nav.Link href="/blog">Blog</Nav.Link> */}
                <Nav.Link className="app-bar-nav nav-link active">
                  Home
                </Nav.Link>
                <Nav.Link href={isLoggedIn ? "/" : "#discover"}>
                  Discover
                </Nav.Link>
                <Nav.Link href={isLoggedIn ? "/" : "#services"}>
                  Services
                </Nav.Link>
                <Nav.Link href={isLoggedIn ? "/" : "#products"}>
                  Products
                </Nav.Link>
              </Nav>
            )}
          </Offcanvas.Body>
        </Navbar.Offcanvas>
        <Nav className="mr-auto">
          <div className="app-bar-nav-items ">
            {!isLoggedIn && (
              <Nav.Link href={isLoggedIn ? "/" : "/auth/login"}>
                Sign In
              </Nav.Link>
            )}
            {isLoggedIn && (
              <>
                <Nav.Link href={isLoggedIn ? "#favorites" : "/auth"}>
                  <FontAwesomeIcon icon={faHeart} />
                </Nav.Link>
                <Nav.Link href={isLoggedIn ? "#notifications" : "/auth"}>
                  <FontAwesomeIcon icon={faBell} />
                </Nav.Link>
                <NavDropdown
                  title={
                    <Image src="https://via.placeholder.com/40" roundedCircle />
                  }
                  id="basic-nav-dropdown"
                >
                  {/* <NavDropdown.Item href="#profile">
                    My Profile
                  </NavDropdown.Item>
                  <NavDropdown.Divider /> */}

                  <NavDropdown.Item onClick={handleSignOut}>
                    Sign Out
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            )}
          </div>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default Appbar;
